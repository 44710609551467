<template>
  <ion-button
    class="lens-button"
    router-link="/lens"
    :router-animation="animation"
  >
    <ion-icon :icon="cameraIcon"></ion-icon>
  </ion-button>
</template>

<script>
import { IonButton, IonIcon, createAnimation } from "@ionic/vue";
import { cameraOutline, closeOutline } from "ionicons/icons";

export default {
  components: {
    IonButton,
    IonIcon,
  },
  data() {
    return {
      closeIcon: closeOutline,
      cameraIcon: cameraOutline,
    };
  },
  created() {
    this.animation = createAnimation();
  },
};
</script>
