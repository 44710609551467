/**
 * Get filters values from storage
 */
export function getSearchHistory(namespace) {
  const history = JSON.parse(localStorage.getItem(`history-${namespace}`));
  return history ?? [];
}

/**
 * Save filters values to storage
 */
export function addToSearchHistory(namespace, value) {
  const history = getSearchHistory(namespace);
  history.push(value);
  if (history.length > 3) {
    history.shift();
  }
  localStorage.setItem(`history-${namespace}`, JSON.stringify(history));
}
