<template>
  <div class="ion-page">
    <ion-header :translucent="true" class="ion-no-border" mode="ios">
      <ion-toolbar>
        <ion-title>{{ $t("filters.countryLabel") }}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="close">
            <ion-icon :icon="closeIcon"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true" class="ion-padding">
      <ion-list>
        <ion-radio-group :value="selected && selected.id" mode="md" @ionChange="onChange">
          <ion-item v-for="item in options" :key="item.id">
            <ion-label class="item-label">{{ item.name }}</ion-label>
            <ion-radio slot="start" :value="item.id"></ion-radio>
          </ion-item>
        </ion-radio-group>
      </ion-list>
    </ion-content>
  </div>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonRadioGroup,
  IonRadio,
  IonItem,
  IonLabel,
  IonList,
  IonTitle,
  IonIcon,
  modalController,
} from "@ionic/vue";
import { close } from "ionicons/icons";

export default {
  components: {
    IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonRadioGroup,
    IonRadio,
    IonItem,
    IonLabel,
    IonList,
    IonTitle,
    IonIcon,
  },
  props: {
    selected: {
      type: Object,
    },
    options: {
      type: Array,
    },
  },
  data() {
    return {
      closeIcon: close,
      closeCallback: null,
      selectedItem: null,
    };
  },
  mounted() {
    if (this.selected) {
      this.selectedItem = this.selected;
    }
  },
  methods: {
    onChange(event) {
      const itemId = parseInt(event.detail.value, 10);
      this.selectedItem = this.options.find((item) => item.id === itemId);
      setTimeout(() => {
        this.apply();
      }, 200);
    },
    close() {
      modalController.dismiss(null);
    },
    apply() {
      modalController.dismiss(this.selectedItem);
    },
  },
};
</script>

<style scoped>
.item-label {
  font-size: 14px;
  white-space: normal;
}
</style>
