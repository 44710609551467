<template>
  <div>
    <ion-app>
      <ion-router-outlet :mode="routerMode" />
    </ion-app>
  </div>
</template>

<script>
import { IonApp, IonRouterOutlet, isPlatform } from "@ionic/vue";
import { SplashScreen } from "@capacitor/splash-screen";
import { StatusBar, Style } from '@capacitor/status-bar';

export default {
  components: {
    IonApp,
    IonRouterOutlet,
  },
  computed: {
    routerMode() {
      return isPlatform("capacitor") && isPlatform("ios") ? "ios" : "md";
    },
  },
  beforeCreate() {
    this.$store.dispatch("init", {
      locale: this.$i18n.locale,
      query: this.$route.query
    });
    // TODO: activate and remove from other pages
    //this.$store.dispatch('location/initGeolocation');
  },
  created() {
    if (isPlatform("capacitor") && isPlatform("ios")) {
      StatusBar.setStyle({
        style: Style.Light,
      });
    }
  },
  mounted() {
    SplashScreen.hide();
  },
  name: "app",
};
</script>

<style lang="scss">
@import "@/assets/fonts/inter/inter.css";

:root {
  font-family: Inter, sans-serif;
  font-feature-settings:
    "liga" 1,
    "calt" 1; /* fix for Chrome */
}
@supports (font-variation-settings: normal) {
  :root {
    font-family: InterVariable, sans-serif;
  }
}

// @font-face {
//   font-family: 'TTRamillas';
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
//   src: url('@/assets/fonts/TTRamillas/TTRamillasBold.ttf') format('truetype');
// }

/* Add more @font-face rules for other weights and styles if needed */


:root {
  //--ion-background-color: #fbfbfbfb;
  //--ion-safe-area-top: 20px;
  //--ion-safe-area-bottom: 22px;

  --ion-font-family: "Inter", sans-serif;
}
ion-backdrop {
  opacity: 0.3;
}

$grid-breakpoints: (
  xs: 0,
  // Phone (0px - 575px)
  sm: 576px,
  // Phablet (576px - 767px)
  md: 768px,
  // Tablet vertical (768px - 991px)
  lg: 992px,
  // Tablet horizontal, Desktop (992px and above)
);

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
}

main {
  background-color: rgba(0, 0, 0, 1) !important;
}

.d-block {
  display: block !important;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

ion-title.primary {
  color: var(--ion-color-primary);
  font-weight: 800;
}

.brand-header {
  display: flex;
  align-items: center;

  a {
    text-decoration: none;
  }
}
.brand-logo {
  color: var(--ion-color-primary);
  font-weight: 800;
  margin: unset;
}

ion-footer {
  --ion-toolbar-border-color: #ffffff;
}

ion-radio-group ion-item {
  --ripple-color: var(--ion-color-light-shade);
  --color-activated: var(--ion-color-dark);
}
ion-radio-group + ion-radio-group {
  ion-list-header {
    padding-top: 40px;
  }
}
ion-list-header {
  --color: #262626;
  position: relative;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0px;
}
ion-card {
  border-radius: 20px;
  --background: var(--ion-color-primary-contrast);

  &.filled {
    box-shadow: none !important;
  }
  &.outlined {
    box-shadow: none;
    border: 1px solid var(--ion-color-dark);
  }
}
ion-checkbox {
  --border-width: 1px;
  --size: 21px;
  &::part(container) {
    padding: 2px;
  }
}
.section-header {
  padding-bottom: 0 !important;
  --color: #262626;
  color: #262626;
  position: relative;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0px;
}

.searchbar-left-aligned.sc-ion-searchbar-ios-h
  .searchbar-input.sc-ion-searchbar-ios {
  padding-inline-start: 35px;
}

.shadow {
  box-shadow: rgba(0, 0, 0, 0.5) 0px 4px 16px;
}
.icon-shadow {
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5));
}

.button-shadow {
  --box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
}

.circle-button {
  --border-radius: 50%;
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  width: 48px; /* Adjust the size as needed */
  height: 48px; /* Adjust the size as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

.wine-about-widget {
  margin: 0 2px 20px 2px;
  min-height: 30px;
  color: var(--ion-color-dark);
}

.muted {
  opacity: 0.5;
}

.display-flex {
  display: flex;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-space-between {
  justify-content: space-between;
}
.align-items-center {
  align-items: center;
}
.flex-shrink-0 {
  flex-shrink: 0;
}

.rate-toast {
  font-weight: 600;
  --border-radius: 20px;

  .toast-header {
    font-weight: 600;
  }
  .toast-message {
    text-align: left;
  }
}

@media only screen and (min-width: 600px) {
  .custom-modal {
    --border-radius: 25px;
  }
}

.padding-bottom-0 {
  padding-bottom: 0 !important;
}

/**
  * Transitions
 */
.fade-in-enter-active,
.fade-in-leave-active {
  transition: all 0.3s ease-out;
}
.fade-in-enter-from {
  opacity: 0.2;
}
.fade-in-leave-to {
  opacity: 0.2;
}
</style>
