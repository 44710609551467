<template>
  <div class="filter" :class="{ active: selected }">
    <ion-button
      class="filter-button"
      fill="outline"
      mode="ios"
      ref="button"
      @click="select"
    >
      <svg class="spinning-image" slot="start" style="width: 25px; margin-right: 5px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="256" height="256" viewBox="0 0 256 256" xml:space="preserve">
        <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
        <path d="M 46.977 6.625 l 8.562 -4.185 c 2.181 -1.066 4.815 -0.21 5.953 1.934 l 4.467 8.418 c 0.651 1.226 1.831 2.084 3.198 2.324 l 9.387 1.647 c 2.391 0.419 4.019 2.66 3.679 5.064 l -1.335 9.436 c -0.194 1.375 0.256 2.762 1.222 3.76 l 6.626 6.849 c 1.688 1.745 1.688 4.514 0 6.259 l -6.626 6.849 c -0.965 0.998 -1.416 2.385 -1.222 3.76 l 1.335 9.436 c 0.34 2.404 -1.288 4.644 -3.679 5.064 l -9.387 1.647 c -1.367 0.24 -2.548 1.097 -3.198 2.324 l -4.467 8.418 c -1.138 2.145 -3.771 3 -5.953 1.934 l -8.562 -4.185 c -1.247 -0.61 -2.706 -0.61 -3.953 0 l -8.562 4.185 c -2.181 1.066 -4.815 0.21 -5.953 -1.934 l -4.467 -8.418 c -0.651 -1.226 -1.831 -2.084 -3.198 -2.324 l -9.387 -1.647 c -2.391 -0.419 -4.019 -2.66 -3.679 -5.064 l 1.335 -9.436 c 0.194 -1.375 -0.256 -2.762 -1.222 -3.76 l -6.626 -6.849 c -1.688 -1.745 -1.688 -4.514 0 -6.259 l 6.626 -6.849 c 0.965 -0.998 1.416 -2.385 1.222 -3.76 l -1.335 -9.436 c -0.34 -2.404 1.288 -4.644 3.679 -5.064 l 9.387 -1.647 c 1.367 -0.24 2.548 -1.097 3.198 -2.324 l 4.467 -8.418 c 1.138 -2.145 3.771 -3 5.953 -1.934 l 8.562 4.185 C 44.271 7.234 45.729 7.234 46.977 6.625 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(216,35,35); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
        <path d="M 33.809 43.004 c -4.801 0 -8.707 -3.906 -8.707 -8.707 c 0 -4.801 3.906 -8.707 8.707 -8.707 s 8.707 3.906 8.707 8.707 C 42.516 39.098 38.61 43.004 33.809 43.004 z M 33.809 29.59 c -2.596 0 -4.707 2.111 -4.707 4.707 c 0 2.596 2.111 4.707 4.707 4.707 s 4.707 -2.111 4.707 -4.707 C 38.516 31.702 36.404 29.59 33.809 29.59 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
        <path d="M 29.056 64.41 c -0.482 0 -0.966 -0.174 -1.35 -0.525 c -0.815 -0.746 -0.87 -2.011 -0.125 -2.825 L 59.47 26.24 c 0.746 -0.814 2.011 -0.871 2.825 -0.125 c 0.814 0.746 0.87 2.011 0.124 2.826 l -31.888 34.82 C 30.137 64.191 29.597 64.41 29.056 64.41 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
        <path d="M 57.169 64.41 c -4.801 0 -8.707 -3.906 -8.707 -8.707 s 3.906 -8.707 8.707 -8.707 s 8.707 3.906 8.707 8.707 S 61.97 64.41 57.169 64.41 z M 57.169 50.996 c -2.596 0 -4.707 2.111 -4.707 4.707 s 2.111 4.707 4.707 4.707 s 4.707 -2.111 4.707 -4.707 S 59.765 50.996 57.169 50.996 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
        </g>
      </svg>
      {{ $t('filters.bestPrice') }}
    </ion-button>
  </div>
</template>

<script>
import { IonButton } from "@ionic/vue";

const EMPTY_VALUE = 0;

export default {
  components: {
    IonButton
  },
  props: {
    value: {
      type: Number,
      required: false,
    },
  },
  mounted() {
    if (this.value) {
      this.selected = this.value;
    }
  },
  watch: {
    value(value) {
      this.selected = value;
    },
  },
  data() {
    return {
      selected: EMPTY_VALUE,
    };
  },
  methods: {
    select() {
      this.selected = this.selected ? EMPTY_VALUE : this.option.id;
      this.apply();
    },
    apply() {
      this.$emit("select", this.selected);
    },
  },
  computed: {
    option() {
      const options = this.$store.state.filters.discountFilters;
      return options ? options[0] : null;
    },
  },
};
</script>
