import { toggleFavoriteWine, toggleFavoriteWineLegacyId } from "@/lib/favorites";

export default {
  namespaced: true,
  state: () => ({
    winesIds: [],
    legacyWinesIds: [],
  }),
  mutations: {
    addWineId(state, id) {
      state.winesIds.push(id);
    },
    removeWineId(state, id) {
      state.winesIds = state.legacyWinesIds.filter((wineId) => wineId !== id);
    },
    addWineLegacyId(state, id) {
      state.winesIds.push(id);
    },
    removeWineLegacyId(state, id) {
      state.winesIds = state.legacyWinesIds.filter((wineId) => wineId !== id);
    }
  },
  actions: {
    async toggleFavoriteWine({ commit, state }, id) {
      toggleFavoriteWine(id);
      if (state.winesIds.includes(id)) {
        commit("removeWineId", id);
      } else {
        commit("addWineId", id);
      }
    },
    async toggleFavoriteWineLegacyId({ commit }, id) {
      toggleFavoriteWineLegacyId(id);
      if (state.winesIds.includes(id)) {
        commit("addWineLegacyId", id);
      } else {
        commit("removeWineLegacyId", id);
      }
    },
  },
};
