<template>
  <ion-page>
    <ion-content>
      <section>
        <div class="top-icon">
          <ion-icon :icon="navigateIcon"></ion-icon>
        </div>
      </section>
      <section>
        <div class="ion-padding onboarding-text">
          {{ $t("start.geolocationRequest") }}
        </div>
      </section>
    </ion-content>
    <ion-footer class="ion-no-border ion-padding">
      <div class="footer-actions">
        <div class="footer-action">
          <ion-button
            v-if="isLocationLoading"
            expand="block"
            mode="ios"
            shape="round"
            disabled
          >
            <ion-spinner color="light" />
          </ion-button>
          <ion-button
            v-else
            class="button"
            expand="block"
            mode="ios"
            shape="round"
            color="light"
            @click="requestGeolocation"
          >
            {{ $t("start.enterAddressButton") }}
          </ion-button>
        </div>
      </div>
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonButton,
  IonContent,
  IonFooter,
  IonIcon,
  IonPage,
  IonSpinner,
  isPlatform,
  modalController,
} from "@ionic/vue";
import { navigateCircleOutline } from "ionicons/icons";

import { getLocationPermissions } from "@/lib/geolocationMapkit";
import { StatusBar, Style } from "@capacitor/status-bar";
import {
  logScreenViewEvent,
  logAllowGeolocationEvent,
  logDenyGeolocationEvent,
} from "@/lib/analytics";
import ModalLocation from "@/components/ModalLocation.vue";

const LOCATION_DENIED = "denied";

export default {
  components: {
    IonButton,
    IonContent,
    IonPage,
    IonFooter,
    IonIcon,
    IonSpinner,
  },
  data() {
    return {
      navigateIcon: navigateCircleOutline,
      isLocationPermissionRequired: true,
      locationPermissions: null,
      isLocationLoading: false,
    };
  },
  computed: {
    isNativeApp() {
      return isPlatform("capacitor");
    },
    isDesktop() {
      return isPlatform("desktop");
    },
  },
  async created() {
    if (isPlatform("capacitor") && isPlatform("ios")) {
      StatusBar.setStyle({
        style: Style.Dark,
      });
    }
    if (this.isNativeApp) {
      const { location } = await getLocationPermissions();
      this.locationPermissions = location;
    }
    logScreenViewEvent("start");
  },
  methods: {
    goToHomepage() {
      this.$store.dispatch("skipStartScreen");
      const target = this.$route.redirectedFrom ?? { name: "Home" };
      this.$router.replace(target);
    },
    async openLocationModal() {
      const modal = await modalController.create({
        component: ModalLocation,
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        this.$store.dispatch("location/setLocationByAutocomplete", data);
      }
      this.goToHomepage();
    },
    async checkGeolocation() {
      if (this.locationPermissions === LOCATION_DENIED) {
        this.openLocationModal();
      } else {
        this.requestGeolocation();
      }
    },
    async requestGeolocation() {
      this.isLocationLoading = true;
      await this.$store.dispatch("location/requestGeolocation");
      const { location } = await getLocationPermissions();
      if (location === LOCATION_DENIED) {
        logDenyGeolocationEvent();
        this.openLocationModal();
      } else {
        logAllowGeolocationEvent();
        this.goToHomepage();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ion-page {
  background-color: var(--ion-color-primary);
}
ion-content {
  --background: var(--ion-color-primary);
}
.top-icon {
  text-align: left;
  padding-top: calc(var(--ion-safe-area-top) + 40px);
  padding-left: 30px;

  ion-icon {
    color: #fff;
    font-size: 4em;
  }
}

.onboarding-text {
  padding-top: 50px;
  font-size: 3em;
  font-weight: 700;
  color: var(--ion-color-primary-contrast);
  text-align: left;
  padding-left: 40px;
  padding-right: 40px;
}

.footer-actions {
  padding-bottom: var(--ion-safe-area-bottom);
}
</style>
