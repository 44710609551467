<template>
  <ion-card
    v-if="wine.rating && wine.rating.score"
    class="wine-about-widget"
    mode="ios"
  >
    <ion-card-content class="wrapper">
      <ion-icon
        v-if="conclusion.icon"
        :icon="conclusion.icon"
        color="primary"
        size="large"
        class="conclusion-icon"
      />
      <div>
        <div class="title">
          {{ conclusion.title }}
        </div>
        <div class="description">{{ conclusion.description }}</div>
      </div>
    </ion-card-content>
  </ion-card>
</template>

<script>
import { IonCard, IonCardContent, IonIcon } from "@ionic/vue";
import {
  diamondOutline,
  ribbonOutline,
  checkmarkCircleOutline,
  helpOutline,
} from "ionicons/icons";

const getRatingConclusion = (score, $t) => {
  const description = '';
  if (score < 3.7) {
    return {
      title: $t("conclusion.average"),
      description,
      icon: null,
    };
  }
  if (score >= 3.7 && score < 4) {
    return {
      title: $t("conclusion.good"),
      description,
      icon: checkmarkCircleOutline,
    };
  }
  if (score >= 4 && score < 4.3) {
    return {
      title: $t("conclusion.great"),
      description,
      icon: ribbonOutline,
    };
  }
  if (score >= 4.3) {
    return {
      title: $t("conclusion.excellent"),
      description,
      icon: diamondOutline,
    };
  }
};

export default {
  components: {
    IonCard,
    IonCardContent,
    IonIcon,
  },
  props: {
    wine: {
      type: Object,
      required: true,
    },
  },
  computed: {
    conclusion() {
      if (this.wine.rating.ratingsCount < 200) {
        return {
          title: this.$t("conclusion.lowReviews"),
          description: this.$t("conclusion.lowReviewsDescription"),
          icon: helpOutline,
        };
      }
      return getRatingConclusion(this.wine.rating.score, this.$t);
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
}
.conclusion-icon {
  margin-right: 15px;
}
.label {
  font-size: 0.8em;
  color: #999;
}
.title {
  font-size: 16px;
  font-weight: 600;
}
.description {
  font-size: 0.8em;
}
</style>
