/**
 * Transform filters to search API payload
 */
export function createSearchParams(state, filters, page, sort = "score") {
  const payload = {
    ...filters,
    location: {
      Latitude: state.location.location.latitude,
      Longitude: state.location.location.longitude,
    },
    page,
    sort,
  };

  if (filters.discount) {
    const discountOption = state.filters.discountFilters.find(
      (option) => option.id === filters.discount,
    );
    payload.discount = {
      from: discountOption.discountMin,
      to: discountOption.discountMax,
    };
  }

  if (!filters.fooddata) {
    payload.fooddata = [];
  } else {
    payload.fooddata = [...filters.fooddata];
  }
  if (payload.fooddata.length === 0) {
    // Add "No meat" as default value
    payload.fooddata.push({ group_id: 1, item_id: 30 });
  }

  return payload;
}

/**
 * Get filter value as a string
 */
export function getFilterStringValue(filters, name, value) {
  if (!value || (Array.isArray(value) && !value.length)) {
    return "Не важно";
  }
  if (name === "wineType") {
    return filters.wineFilters.find((item) => item.id === value).name;
  }
  if (name === "shopChain") {
    return filters.shopChainFilters.find((item) => item.id === value).name;
  }
  if (name === "fooddata") {
    const group = filters.foodFilters.find(
      (item) => item.id === value[0].group_id,
    );
    return group.items.find((item) => item.id === value[0].item_id).name;
  }
  if (name === "country") {
    return filters.countryFilters.find((item) => item.id === value).name;
  }
  if (name === "blend") {
    return filters.blendFilters.find((item) => item.id === value).name;
  }
  if (name === "sugar") {
    return filters.sugarFilters.find((item) => item.id === value).name;
  }
  if (name === "price") {
    return `${value.from} - ${value.to}`;
  }
  if (name === "discount") {
    return `from ${value}`;
  }
  if (name === "wineStyle") {
    return filters.wineStyleFilters.find((item) => item.id === value).name;
  }
}

/**
 * Get filters initial values (Deprecated)
 */
export function getFiltersInitialValues(query) {
  const {
    price,
    wineType,
    fooddata,
    shop,
    shopChain,
    blend,
    country,
    tag,
    discount,
    sugar,
    wineStyle,
  } = query;
  const saved = getSavedFilters();

  return {
    price: price || saved.price,
    wineType: parseInt(wineType, 10) || 0,
    fooddata: fooddata || [],
    shop: parseInt(shop, 10) || 0,
    shopChain: parseInt(shopChain, 10) || 0,
    blend: parseInt(blend, 10) || 0,
    country: parseInt(country, 10) || 0,
    tag: tag,
    discount: discount || 0,
    sugar: sugar || 0,
    wineStyle: wineStyle || 0,
  };
}

export function getFiltersValuesFromQuery(query) {
  if (!query) {
    return {};
  }
  const {
    wineType,
    fooddata,
    shop,
    shopChain,
    blend,
    country,
    tag,
    discount,
    sugar,
    wineStyle,
  } = query;

  const values = {};
  if (parseInt(wineType, 10)) {
    values.wineType = parseInt(wineType, 10);
  }
  if (fooddata) {
    values.fooddata = fooddata;
  }
  if (parseInt(shop, 10)) {
    values.shop = parseInt(shop, 10);
  }
  if (parseInt(shopChain, 10)) {
    values.shopChain = parseInt(shopChain, 10);
  }
  if (parseInt(shopChain, 10)) {
    values.shopChain = parseInt(shopChain, 10);
  }
  if (parseInt(blend, 10)) {
    values.blend = parseInt(blend, 10);
  }
  if (parseInt(country, 10)) {
    values.country = parseInt(country, 10);
  }
  if (tag) {
    values.tag = tag;
  }
  if (parseInt(discount, 10)) {
    values.discount = parseInt(discount, 10);
  }
  if (parseInt(sugar, 10)) {
    values.sugar = parseInt(sugar, 10);
  }
  if (parseInt(wineStyle, 10)) {
    values.wineStyle = parseInt(wineStyle, 10);
  }
  return values;
}

/**
 * Get filters values from storage
 */
export function getSavedFilters() {
  const saved = JSON.parse(localStorage.getItem("selectedFilters"));
  return saved ?? {};
}

/**
 * Save filters values to storage
 */
export function saveFilter(filter, value) {
  const saved = getSavedFilters();
  saved[filter] = value;
  localStorage.setItem("selectedFilters", JSON.stringify(saved));
}
