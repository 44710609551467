<template>
  <div class="filter" :class="{ active: selectedItemName }" ref="filter">
    <ion-button
      class="filter-button"
      fill="outline"
      mode="ios"
      @click="openModal"
    >
      <span>{{ selectedItemName || $t("filters.countryLabel") }}</span>
    </ion-button>
  </div>
</template>

<script>
import { IonButton, modalController } from "@ionic/vue";
import ModalCountry from "@/components/ModalCountry.vue";

export default {
  components: {
    IonButton,
  },
  props: {
    value: {
      type: Number,
    },
  },
  data() {
    return {
      selected: 0,
    };
  },
  mounted() {
    if (this.value) {
      this.selected = this.value;
    }
  },
  watch: {
    value(value) {
      this.selected = value;
    },
  },
  methods: {
    async openModal() {
      const modal = await modalController.create({
        component: ModalCountry,
        componentProps: {
          options: this.options,
          selected: this.selectedItem,
        },
        cssClass: "custom-modal",
        presentingElement: document.getElementById("home-page"),
        canDismiss: true,
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        this.selected = data.id;
        this.$emit("select", data.id);
      }
    },
  },
  computed: {
    options() {
      const options = this.$store.state.filters.countryFilters || [];
      const emptyItem = { id: 0, name: this.$t("filters.noMatters") };
      return [emptyItem, ...options];
    },
    selectedItem() {
      if (this.options) {
        return this.options.find((item) => this.selected === item.id);
      }
      return null;
    },
    selectedItemName() {
      return this.selectedItem && this.selectedItem.id !== 0
        ? this.selectedItem.name
        : null;
    },
  },
};
</script>
