<template>
  <div v-if="this.temperature">
    <ion-card class="wine-about-widget" mode="ios">
      <ion-card-content class="temperature-wrapper">
        <div class="temperature">
          <ion-icon :icon="snowIcon" class="temperature-icon" />
          <div class="temperature-degrees">{{ temperature.degrees }}°С</div>
        </div>
        <div class="temperature-description">
          {{ temperature.timeFridge }} {{ $t("temperature.fridge") }}<br />
          {{ temperature.timeFreezer }} {{ $t("temperature.freezer") }}
        </div>
      </ion-card-content>
    </ion-card>
  </div>
</template>

<script>
import { IonCard, IonCardContent, IonIcon } from "@ionic/vue";
import { snowOutline } from "ionicons/icons";

const TYPE_RED = 1;
const TYPE_WHITE = 2;
const TYPE_ROSE = 3;
const TYPE_SPARKLING = 4;

const GROUP_RED_FULL = 1;
const GROUP_RED_MEDIUM = 2;
const GROUP_RED_LIGHT = 3;
const GROUP_ROSE = 4;
const GROUP_WHITE_FULL = 5;
const GROUP_WHITE_LIGHT = 6;
const GROUP_SPARKLING = 7;

const getTemperatureByWineGroup = (groupId, $tc) => {
  const data = [
    {
      wineType: TYPE_RED,
      group: GROUP_RED_FULL,
      degrees: "17-21",
      timeFridge: $tc("temperature.minutes", 30),
      timeFreezer: $tc("temperature.minutes", 10),
    },
    {
      wineType: TYPE_RED,
      group: GROUP_RED_MEDIUM,
      degrees: "13-16",
      timeFridge: $tc("temperature.hours", 1),
      timeFreezer: $tc("temperature.minutes", 20),
    },
    {
      wineType: TYPE_RED,
      group: GROUP_RED_LIGHT,
      degrees: "12-14",
      timeFridge:
        $tc("temperature.hours", 1) + " " + $tc("temperature.minutes", 30),
      timeFreezer: $tc("temperature.minutes", 25),
    },
    {
      wineType: TYPE_WHITE,
      group: GROUP_WHITE_FULL,
      degrees: "13-16",
      timeFridge: $tc("temperature.hours", 1),
      timeFreezer: $tc("temperature.minutes", 20),
    },
    {
      wineType: TYPE_WHITE,
      group: GROUP_WHITE_LIGHT,
      degrees: "7-14",
      timeFridge: $tc("temperature.hours", 2),
      timeFreezer: $tc("temperature.minutes", 30),
    },
    {
      wineType: TYPE_ROSE,
      group: GROUP_ROSE,
      degrees: "8-12",
      timeFridge:
        $tc("temperature.hours", 1) + "" + $tc("temperature.minutes", 30),
      timeFreezer: $tc("temperature.minutes", 40),
    },
    {
      wineType: TYPE_SPARKLING,
      group: GROUP_SPARKLING,
      degrees: "5-10",
      timeFridge: $tc("temperature.hours", 3),
      timeFreezer: $tc("temperature.minutes", 60),
    },
  ];
  return data.find((item) => groupId === item.group);
};

const getTemperatureByWineType = (typeId, $tc) => {
  const data = [
    {
      type: TYPE_RED,
      temperature: getTemperatureByWineGroup(GROUP_RED_FULL, $tc),
    },
    {
      type: TYPE_WHITE,
      temperature: getTemperatureByWineGroup(GROUP_WHITE_LIGHT, $tc),
    },
    {
      type: TYPE_SPARKLING,
      temperature: getTemperatureByWineGroup(GROUP_SPARKLING, $tc),
    },
    {
      type: TYPE_ROSE,
      temperature: getTemperatureByWineGroup(GROUP_ROSE, $tc),
    },
  ];
  return data.find((item) => typeId === item.type).temperature;
};

export default {
  components: {
    IonCard,
    IonCardContent,
    IonIcon,
  },
  props: {
    wine: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      snowIcon: snowOutline,
    };
  },
  computed: {
    temperature() {
      return this.wine.group && this.wine.group.id
        ? getTemperatureByWineGroup(this.wine.group.id, this.$tc)
        : getTemperatureByWineType(this.wine.type.id, this.$tc);
    },
  },
};
</script>

<style>
.temperature-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.temperature,
.temperature-icon {
  color: rgb(45, 114, 160);
}
.temperature {
  display: flex;
  font-size: 1.2em;
  flex-wrap: wrap;
  align-items: center;
}
.temperature-degrees {
  display: block;
}
.temperature-icon {
  display: block;
  font-size: 1.8em;
  font-weight: 300;
  margin-right: 10px;
}
.temperature-description {
  margin-left: 10px;
  font-size: 0.8em;
}
</style>
