<template>
  <ion-page>
    <ion-content>
      <div class="wrapper">
        <div v-show="!isLoading" class="camera-frame">
          <div class="corner top-left"></div>
          <div class="corner top-right"></div>
          <div class="corner bottom-left"></div>
          <div class="corner bottom-right"></div>
        </div>

      </div>

      <img :src="result" width="300">

    </ion-content>
  </ion-page>
</template>

<script>
import resizePhoto from "@/lib/resizePhoto";

export default {
  name: "Resize",
  data() {
    return {
      isLoading: false,
      result: null,
    };
  },
  mounted() {
    this.resizePhoto();
  },
  methods: {
    async resizePhoto() {
      this.result = await resizePhoto(this.$refs.image.src);
    },
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
}

.camera-frame {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;

  .corner {
    position: absolute;
    width: 60px;
    height: 60px;
    border: 5px solid #fff;
    border-radius: 30px;
  }

  .top-left {
    top: 0;
    left: 0;
    border-bottom: none;
    border-right: none;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }

  .top-right {
    top: 0;
    right: 0;
    border-bottom: none;
    border-left: none;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
  }

  .bottom-left {
    bottom: 0;
    left: 0;
    border-top: none;
    border-right: none;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .bottom-right {
    bottom: 0;
    right: 0;
    border-top: none;
    border-left: none;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
