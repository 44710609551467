<template>
  <div class="filter" :class="{ active: isOpened || selectedItemsNumber }">
    <div>
      <ion-button
        class="filter-button"
        fill="outline"
        mode="ios"
        ref="button"
        @click="isOpened = true; event = $event"
      >
        <slot name="icon"></slot>
        <span>{{ title }}</span>
        <ion-badge
          v-if="selectedItemsNumber > 1"
          color="dark"
          class="selected-items-number"
        >
          {{ selectedItemsNumber }}
        </ion-badge>
      </ion-button>
    </div>
    <ion-popover
      :is-open="isOpened"
      :arrow="false"
      :animated="false"
      :show-backdrop="false"
      side="bottom"
      :event="event"
      mode="ios"
      @didDismiss="isOpened = false"
      class="filter-popover"
    >
      <div class="popover-content">
        <slot></slot>
        <ion-button
          v-if="applyButton"
          class="d-block"
          fill="outline"
          color="primary"
          shape="round"
          mode="ios"
          @click="apply"
        >
          {{ this.$t("filters.applyButton") }}
        </ion-button>
      </div>
    </ion-popover>
  </div>
</template>

<script>
import { IonButton, IonBadge, IonPopover } from "@ionic/vue";

export default {
  components: {
    IonButton,
    IonBadge,
    IonPopover,
  },
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    value: {
      required: false,
    },
    applyButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isOpened: false,
      event: null,
    };
  },
  methods: {
    apply() {
      this.$emit("apply");
      this.isOpened = false;
    },
    closePopover() {
      this.isOpened = false;
    },
  },
  computed: {
    title() {
      if (this.value instanceof Array) {
        return this.value[0];
      }
      return this.value ? this.value : this.placeholder;
    },
    selectedItemsNumber() {
      if (this.value instanceof Array) {
        return this.value.length;
      }
      return this.value ? 1 : 0;
    },
  },
};
</script>

<style lang="scss">
.filter {
  display: inline-block;

  ion-radio {
    margin-inline-end: 20px;
  }

  .filter-button {
    --background: transparent;
    --border-radius: 25px;
    //--border-width: 2px;
    opacity: 0.9;
    --background-activated: transparent;
    --border-color: var(--ion-color-dark);
    --color: var(--ion-color-dark);
    --color-activated: var(--ion-color-dark);

    transition:
      transform 500ms cubic-bezier(0.12, 0.72, 0.29, 1) 0s,
      -webkit-transform 500ms cubic-bezier(0.12, 0.72, 0.29, 1) 0s;

    &.ion-activated {
      //opacity: 1;
      color: var(--ion-color-primary);
      transform: scale(0.9);
    }
  }
  &.active {
    .filter-button {
      opacity: 1;
      --border-color: var(--ion-color-primary);
      --color: var(--ion-color-primary);
    }
  }

  .select-icon-inner {
    display: none !important;
  }

}

.filter-popover {
  --width: 230px;
  --box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
  --offset-y: 25px;

  &::part(content) {
    border-radius: 15px;
  }
}
.popover-content {
  padding: 10px;
}

.filter-alert {
  .alert-checkbox-group.sc-ion-alert-ios {
    border-top: 0;
  }
  .alert-button {
    border-top: 0;
  }
  .alert-button-group {
    padding-top: 10px;
  }
}

.selected-items-number {
  margin-left: 5px;
}
</style>
