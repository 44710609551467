<template>
  <div class="ion-padding" style="padding-top: 0; padding-bottom: 5px">
    <!-- <ion-searchbar
      class="searchbar"
      :placeholder="$t('search.searchbarPlaceholder')"
      debounce="500"
      ref="searchbar"
      :cancel-button-text="$t('search.searchbarCancel')"
      mode="md"
      @ionFocus="$emit('focus')"
    /> -->
    <div class="searchbar" tabindex="0" @click="$emit('focus')">
      <div class="searchbar-inner">
        <ion-icon :icon="searchIcon" class="search-icon" />
        <span>{{ $t("search.searchbarPlaceholder") }}</span>
      </div>
      <ion-button
        @click.stop="$router.push('/lens')"
        fill="clear"
        color="dark"
        class="lens-button"
        style="--ripple-color: transparent;"
      >
        <img src="@/assets/camera.svg" width="30" height="30" />
      </ion-button>
    </div>
  </div>
</template>

<script>
import { IonButton, IonIcon } from "@ionic/vue";
import { search } from "ionicons/icons";

export default {
  components: {
    IonIcon,
    IonButton,
  },
  data() {
    return {
      searchIcon: search,
    };
  },
};
</script>

<style lang="scss" scoped>
// .searchbar {
//   padding: 0;
//   --box-shadow: 0;
//   --background: #f6f5f5;
//   --border-radius: 15px;
// }
.searchbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  background-color: #f6f5f5;
  border-radius: 15px;

  border: 1px solid var(--ion-color-dark);
  background-color: transparent;
  //box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
}
.searchbar-inner {
  display: flex;

  span {
    font-weight: 500;
    color: var(--ion-color-medium);
  }
}
.search-icon {
  font-size: 20px;
  margin: 0 15px;
}
.lens-button {
  ion-icon {
    font-size: 25px;
  }
}
</style>
