<template>
  <select name="cities" @change="onSelect" class="select">
    <option :value="null">Выберите город</option>
    <option v-for="city in cities" :value="city.name" :key="city.id">
      {{ city.name }}
    </option>
  </select>
</template>

<script>
import config from "@/app.config";
import axios from "axios";

export default {
  mounted() {
    this.fetchCities();
  },
  data() {
    return {
      cities: [],
    };
  },
  methods: {
    onSelect(event) {
      const city = this.cities.find((city) => city.name === event.target.value);

      this.$store.commit("location/setLocation", {
        latitude: parseFloat(city.location.lat),
        longitude: parseFloat(city.location.lon),
        city: city.name,
        countryCode: "RU",
        isDefault: false,
      });

      this.$emit("select", city);
    },
    async fetchCities() {
      const result = await axios.get(`${config.apiHost}/pairing/cities.ashx`);
      this.cities = result.data;
    },
  },
};
</script>

<style scoped>
.select {
  padding: 6px;
  border-radius: 4px;
  border: 2px var(--ion-color-dark) solid;
  background-color: var(--ion-background-color);

  font-weight: 600;
  color: var(--ion-color-dark);
}
</style>
