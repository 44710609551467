import axios from "axios";
import { isPlatform } from "@ionic/vue";
// import {
//   getCurrentLocation,
//   getAddressByCoords,
//   getLocationByAutocomplete,
//   getLocationPermissions,
//   createLocation,
//   loadMapkit,
// } from "@/lib/geolocationMapkit";

import { setUserPropertyLocation, logError } from "@/lib/analytics";

const isNativeApp = () => {
  return isPlatform("capacitor");
};

let getCurrentLocation, getAddressByCoords, getLocationPermissions, createLocation, loadMapkit, getLocationByAutocomplete;

if (localStorage.getItem("useDaData") === "true") {
  getCurrentLocation = require("@/lib/geolocation").getCurrentLocation;
  getAddressByCoords = require("@/lib/geolocation").getAddressByCoords;
  getLocationPermissions = require("@/lib/geolocation").getLocationPermissions;
  createLocation = require("@/lib/geolocation").createLocation;
  loadMapkit = require("@/lib/geolocation").loadMapkit;
  getLocationByAutocomplete = require("@/lib/geolocation").getLocationByAutocomplete;
} else {
  getCurrentLocation = require("@/lib/geolocationMapkit").getCurrentLocation;
  getAddressByCoords = require("@/lib/geolocationMapkit").getAddressByCoords;
  getLocationPermissions = require("@/lib/geolocationMapkit").getLocationPermissions;
  createLocation = require("@/lib/geolocationMapkit").createLocation;
  loadMapkit = require("@/lib/geolocationMapkit").loadMapkit;
  getLocationByAutocomplete = require("@/lib/geolocationMapkit").getLocationByAutocomplete;
}

const PERMISSION_DENIED = "denied";
const ERROR_LOCATION_DENIED = 1;

export default {
  namespaced: true,
  state: () => ({
    coordinates: null,
    location: null,
    locationLoading: false,
    locationError: false,
    isLocationBlocked: false,
    isLocationInitialized: false,
    events: [],
  }),
  mutations: {
    setCoordinates(state, coordinates) {
      state.coordinates = coordinates;
    },
    setLocation(state, location) {
      state.location = location;

      axios.defaults.headers.common = {
        ...axios.defaults.headers.common,
        "X-App-Country": location.countryCode,
      };
    },
    setLocationLoading(state, locationLoading) {
      state.locationLoading = locationLoading;
    },
    setLocationError(state, locationError) {
      state.locationError = locationError;
    },
    setLocationBlocked(state) {
      state.isLocationBlocked = true;
    },
    setLocationInitialized(state) {
      state.isLocationInitialized = true;
    },
    addEvent(state, event) {
      state.events.push(event);
    }
  },
  actions: {
    /**
     * Get current coordinates and transform them to address
     */
    async requestGeolocation({ commit, rootState, dispatch }) {
      commit("setLocationLoading", true);
      await loadMapkit();
      commit("addEvent", "mapkit loaded");
      try {
        let enableHighAccuracy = localStorage.getItem("enableHighAccuracy");
        if (enableHighAccuracy === null) {
          enableHighAccuracy = true;
          localStorage.setItem("enableHighAccuracy", enableHighAccuracy);
        }

        const { coords } = await getCurrentLocation(enableHighAccuracy);
        commit("addEvent", "coordinates detected");
        const { results } = await getAddressByCoords(
          coords.latitude,
          coords.longitude,
          rootState.locale,
        );
        commit("addEvent", "address detected from mapkit");
        const geocoderResult = results[0]; //59.943918, 30.356387
        const location = createLocation(geocoderResult, coords);
        commit("setLocation", location);
        dispatch("setCurrencyByCountry", geocoderResult.countryCode, {
          root: true,
        });
        setUserPropertyLocation(location);
      } catch (e) {
        logError(e);
        commit("addEvent", "location error");
        commit("setLocationError", true);
        if (e.code === ERROR_LOCATION_DENIED) {
          commit("setLocationBlocked");
          commit("addEvent", "location blocked");
        }
        dispatch("setFallbackLocation");
      } finally {
        commit("setLocationLoading", false);
      }
    },
    /**
     * Get place item by autocomplete location object
     */
    async setLocationByAutocomplete(
      { commit, rootState },
      autocompleteLocation,
    ) {
      commit("setLocationLoading", true);
      try {
        const { places } = await getLocationByAutocomplete(
          autocompleteLocation,
          rootState.locale,
        );
        const geocoderResult = places[0];
        console.log("Set address from autocomplete", geocoderResult);
        const location = createLocation(geocoderResult);
        commit("setLocation", location);
        setUserPropertyLocation(location);
      } catch (e) {
        logError(e);
        commit("setLocationError", true);
      } finally {
        commit("setLocationLoading", false);
      }
    },
    /**
     * Entry point
     */
    async checkGeolocation({ dispatch, commit }) {
      if (isNativeApp()) {
        const permissions = await getLocationPermissions();
        if (permissions.location === PERMISSION_DENIED) {
          commit("setLocationBlocked");
          dispatch("setFallbackLocation");
          return;
        }
      }
      await dispatch("requestGeolocation");
    },
    /**
     * Initialize geolocation once
     */
    async initGeolocation({ state, commit, dispatch }) {
      if (!state.isLocationInitialized) {
        await dispatch("checkGeolocation");
        commit("setLocationInitialized");
      }
    },
    /**
     * Set fallback location
     */
    async setFallbackLocation({ commit, rootState }) {
      const defaultCoords =
        rootState.locale === "ru"
          ? { latitude: 55.755826, longitude: 37.6173 } // Moscow
          : { latitude: 60.169771, longitude: 24.938478 }; // Helsinki

      commit("setLocation", createLocation(null, defaultCoords));
    },
  },
};
