<template>
  <span>
    <ion-button
      mode="ios"
      id="trigger-button"
      fill="clear"
      size="small"
      @click="setOpen(true, $event)"
      style="font-size: 12px; vertical-align: top"
    >
      <ion-icon slot="icon-only" :icon="icon" color="primary"></ion-icon>
    </ion-button>
    <ion-popover
      :is-open="isOpen"
      :event="event"
      :translucent="true"
      @didDismiss="setOpen(false)"
    >
      <ion-content class="popover-content">
        <div class="hint-content ion-padding">
          {{ $t("rating.descriptionShort") }}<br /><br />
          {{ $t("rating.descriptionLong") }}
        </div>
      </ion-content>
    </ion-popover>
  </span>
</template>

<script>
import { IonButton, IonContent, IonIcon, IonPopover } from "@ionic/vue";
import { helpCircleOutline } from "ionicons/icons";

export default {
  components: {
    IonButton,
    IonContent,
    IonIcon,
    IonPopover,
  },
  data() {
    return {
      icon: helpCircleOutline,
      isOpen: false,
      event: null,
    };
  },
  methods: {
    setOpen(isOpen, $event) {
      this.event = $event;
      this.isOpen = isOpen;
    },
  },
};
</script>

<style scoped lang="scss">
.popover-content::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
.hint-content {
  text-align: left;
  font-size: 12px;
  line-height: 18px;
}
</style>
