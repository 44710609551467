<template>
  <ion-card class="wine-about-widget wine-shop-card" target="_blank" mode="ios">
    <ion-card-content v-if="shop">
      <div class="wine-shop-price-container ion-margin-bottom">
        <div class="wine-shop-price">
          <span class="actual-price"
            >{{ Math.floor(price) }} {{ getCurrencyByCode(currency) }}</span
          >
          <span v-if="basePrice && basePrice !== price" class="base-price">
            {{ Math.floor(basePrice) }}
            {{ getCurrencyByCode(currency) }}
          </span>
        </div>
        <img :src="shopLogo" class="wine-shop-logo" />
      </div>

      <span
        v-if="hasOnlineDiscount && onlineDiscount"
        class="price-description ion-margin-bottom"
      >
        <ion-badge style="margin-right: 5px">-{{ onlineDiscount }}%</ion-badge>
        <span>{{ $t("wine.onlinePrice") }}</span>
      </span>

      <div class="wine-shop ion-margin-bottom">
        <div class="wine-shop-address">
          <span>{{ shop.chain.name }}</span>
          <div>{{ shop.address }}</div>
        </div>
      </div>

      <shop-map v-if="shop" :shop="shop" class="ion-margin-bottom" />

      <ion-button
        :href="wine.url"
        target="_blank"
        color="primary"
        expand="block"
        fill="outline"
        :strong="true"
        mode="ios"
        @click="logGoToStoreEvent(wine)"
      >
        {{
          hasOnlineDiscount
            ? $t("wine.orderButton")
            : $t("wine.goToStoreButton")
        }}
      </ion-button>
    </ion-card-content>

    <ion-card-content v-else>
      <div class="wine-shop-price-container">
        <div class="wine-shop-price">
          {{ $t("wine.outOfStock") }}
        </div>
      </div>
    </ion-card-content>
  </ion-card>
</template>

<script>
import { IonCard, IonCardContent, IonButton, IonBadge } from "@ionic/vue";
import { logGoToStoreEvent } from "@/lib/analytics";
import { getCurrencyByCode } from "@/lib/currency";
import ShopMap from "@/components/ShopMap.vue";

export default {
  components: {
    IonCard,
    IonCardContent,
    IonButton,
    IonBadge,
    ShopMap,
  },
  props: {
    wine: {
      type: Object,
      required: true,
    },
  },
  computed: {
    shopLogo() {
      const path = this.shop?.chain.logo;
      return path ? `https://app.wineradar.ru${path}` : null;
    },
    shop() {
      return this.wine.shops && this.wine.shops[0];
    },
    basePrice() {
      return this.wine.basePrice || this.shop?.basePrice;
    },
    price() {
      return this.wine.price || this.shop?.price;
    },
    currency() {
      return this.wine.currency || this.shop?.currency;
    },
    hasOnlineDiscount() {
      return this.wine.hasOnlineDiscount || this.shop?.hasOnlineDiscount;
    },
    onlineDiscount() {
      if (!this.basePrice) {
        return 0;
      }
      const discount = this.basePrice - this.price;
      return discount > 0 ? Math.ceil((discount * 100) / this.basePrice) : 0;
    },
  },
  methods: {
    logGoToStoreEvent,
    getCurrencyByCode,
  },
};
</script>

<style lang="scss" scoped>
.wine-shop {
  display: flex;
  align-items: center;

  &-address {
    flex: 1;
    color: var(--ion-color-dark);
    font-size: 0.8em;

    span {
      font-weight: 600;
      font-size: 16px;
      display: block;
    }
  }
  &-price {
    margin-right: 10px;

    .base-price {
      font-size: 18px;
      text-decoration: line-through;
      padding-right: 10px;
    }
    .actual-price {
      font-size: 26px;
      padding-right: 10px;
    }
  }
}
.wine-shop-price-container {
  display: flex;
  justify-content: space-between;
}
.price-description {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  align-items: center;
}
.wine-shop-logo {
  display: block;
  max-height: 40px;
  max-width: 120px;
  object-fit: contain;
}
.price-box {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
</style>
