<template>
  <section class="filters">
    <template v-if="filtersLoading || !filters">
      <ion-skeleton-text
        animated
        style="width: 100px; height: 45px"
        class="filter-text-placeholder"
      />
      <ion-skeleton-text
        animated
        style="width: 100px; height: 45px"
        class="filter-text-placeholder"
      />
      <ion-skeleton-text
        animated
        style="width: 100px; height: 45px"
        class="filter-text-placeholder"
      />
      <ion-skeleton-text
        animated
        style="width: 100px; height: 45px"
        class="filter-text-placeholder"
      />
    </template>
    <template v-else-if="filters">
      <filter-discount
        :value="filtersValues.discount"
        @select="onFilterApply('discount', $event)"
      />
      <filter-price
        :value="filtersValues.price"
        @select="onFilterApply('price', $event)"
      />
      <filter-type
        :value="filtersValues.wineType"
        @select="onFilterApply('wineType', $event)"
      />
      <filter-wine-style
        :value="filtersValues.wineStyle"
        :wine-type="filtersValues.wineType"
        @select="onFilterApply('wineStyle', $event)"
        v-show="wineStyleIsVisible"
      />
      <filter-country
        :value="filtersValues.country"
        @select="onFilterApply('country', $event)"
      />
      <filter-blend
        :value="filtersValues.blend"
        @select="onFilterApply('blend', $event)"
      />
      <!-- <filter-pairing
        :value="filtersValues.fooddata"
        @select="onFilterApply('fooddata', $event)"
      /> -->
      <filter-shop-chain
        :value="filtersValues.shopChain"
        @select="onFilterApply('shopChain', $event)"
      />
      <filter-sugar
        :value="filtersValues.sugar"
        @select="onFilterApply('sugar', $event)"
      />
      <ion-button
        v-show="hiddenFiltersAreSelected"
        fill="clear"
        size="small"
        mode="ios"
        style="font-weight: 500"
        @click="resetFilters"
      >
        {{ $t("filters.resetButton") }}
      </ion-button>
    </template>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { IonButton, IonSkeletonText } from "@ionic/vue";
import { logFilterChangeEvent } from "@/lib/analytics";
import { getFilterStringValue } from "@/lib/filters";
import FilterDiscount from "@/components/FilterDiscount.vue";
//import FilterPairing from "@/components/FilterPairing.vue";
import FilterPrice from "@/components/FilterPrice.vue";
import FilterType from "@/components/FilterType.vue";
import FilterShopChain from "@/components/FilterShopChain.vue";
import FilterBlend from "@/components/FilterBlend.vue";
import FilterCountry from "@/components/FilterCountry.vue";
import FilterSugar from "@/components/FilterSugar.vue";
import FilterWineStyle from "@/components/FilterWineStyle.vue";

import { getFiltersInitialValues, saveFilter } from "@/lib/filters";

export default {
  components: {
    FilterDiscount,
    FilterPrice,
    FilterType,
    //FilterPairing,
    FilterShopChain,
    FilterBlend,
    FilterCountry,
    FilterSugar,
    FilterWineStyle,
    IonButton,
    IonSkeletonText,
  },
  data() {
    return {
      moreFiltersChecked: false,
      wineStyleIsVisible: true,
    };
  },
  computed: {
    ...mapState(["filters", "filtersLoading", "filtersValues"]),
    isMoreFiltersVisible() {
      return (
        this.moreFiltersChecked ||
        this.filtersValues.fooddata.length ||
        this.filtersValues.shopChain ||
        this.filtersValues.country ||
        this.filtersValues.blend
      );
    },
    hiddenFiltersAreSelected() {
      const active = Object.keys(this.filtersValues).map((key) => {
        if (key === "price") {
          return false;
        }
        const value = this.filtersValues[key];
        if (Array.isArray(value)) {
          return value.length > 0;
        }
        return Boolean(value);
      });

      return active.filter(Boolean).length;
    },
  },
  methods: {
    onFilterApply(filter, value) {
      if (filter) {
        const update = {};
        update[filter] = value;

        // Special rules for bounded filters
        if (filter === "blend" && value !== 0) {
          update["wineType"] = 0;
        }
        if (filter === "wineType" && value !== 0) {
          update["blend"] = 0;
        }
        if (update["wineType"]) {
          update["wineStyle"] = 0;
        }

        this.$store.commit("setFiltersValues", {
          ...this.filtersValues,
          ...update,
        });

        if (filter === "price") {
          saveFilter(filter, value);
        }
      }

      this.$emit("select", this.filtersValues);
      this.resetQueryString();
      logFilterChangeEvent(
        filter,
        getFilterStringValue(this.filters, filter, value),
      );
    },
    resetQueryString() {
      this.$router.push({ path: this.$route.path });
    },
    resetFilters() {
      this.$store.commit("setFiltersValues", {
        ...getFiltersInitialValues({ price: this.filtersValues.price }),
      });
      this.$emit("select", this.filtersValues);
      this.resetQueryString();
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-text-placeholder {
  border-radius: 25px;
  display: inline-block;
  margin-right: 5px;
}
.filters {
  display: flex;
  align-items: center;
  overflow-x: scroll;
  flex-wrap: nowrap;
  scroll-snap-type: x mandatory; /* scrollIntoView does not work in Safari iOS with scroll-snap */

  &::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    height: 0px;
    display: none;
    background: transparent; /* Optional: just make scrollbar invisible */
  }
}
</style>
