import "@capacitor-community/firebase-analytics";
import { isPlatform } from "@ionic/vue";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

const isNativeApp = () => {
  return isPlatform("capacitor");
};

export function setUserPropertyLocation(location) {
  if (isNativeApp() && location.city) {
    FirebaseAnalytics.setUserProperty({
      name: "city",
      value: location.city,
    });
  }
}

export function setUserPropertyDeviceId(deviceId) {
  if (isNativeApp()) {
    FirebaseAnalytics.setUserProperty({
      name: "deviceId",
      value: deviceId,
    });
  }
}

export function logScreenViewEvent(screenName) {
  if (isNativeApp()) {
    FirebaseAnalytics.setScreenName({
      screenName,
    });
    FirebaseAnalytics.logEvent({
      name: "screen_view",
      params: {
        screen: screenName,
      },
    });
  }
}

export function logConfirmAgeEvent() {
  if (isNativeApp()) {
    FirebaseAnalytics.logEvent({
      name: "confirm_age",
    });
  }
}

export function logAllowGeolocationEvent() {
  if (isNativeApp()) {
    FirebaseAnalytics.logEvent({
      name: "allow_geolocation",
    });
  }
}

export function logDenyGeolocationEvent() {
  if (isNativeApp()) {
    FirebaseAnalytics.logEvent({
      name: "deny_geolocation",
    });
  }
}

export function logStoryViewEvent(storyTitle) {
  if (isNativeApp()) {
    FirebaseAnalytics.logEvent({
      name: "select_content",
      params: {
        content_type: "story",
        content_id: storyTitle,
      },
    });
  }
}

export function logWineSelectEvent(wine) {
  if (isNativeApp()) {
    FirebaseAnalytics.logEvent({
      name: "select_item",
      params: {
        items: [
          {
            item_id: wine.id,
            item_name: wine.name,
            affiliation: wine.shops ? wine.shops[0]?.chain.id : null,
            currency: "RUB",
            item_category: wine.type?.id,
            price: wine.price,
            item_list_name: "Nearby",
          },
        ],
        item_list_name: "Nearby",
      },
    });
  }
}

export function logWineViewEvent(wine) {
  if (isNativeApp()) {
    FirebaseAnalytics.logEvent({
      name: "view_item",
      params: {
        items: [
          {
            item_id: wine.id,
            item_name: wine.name,
            affiliation: wine.shops ? wine.shops[0]?.chain.id : null,
            currency: "RUB",
            item_category: wine.type?.id,
            price: wine.price,
          },
        ],
        currency: "RUB",
        value: wine.price,
      },
    });
  }
}

export function logWineListViewEvent(wines, listName, page) {
  if (isNativeApp()) {
    FirebaseAnalytics.logEvent({
      name: "view_item_list",
      params: {
        items: wines.map((wine) => ({
          item_id: wine.id,
          item_name: wine.name,
          affiliation: wine.shops ? wine.shops[0]?.chain.id : null,
          currency: "RUB",
          item_category: wine.type?.id,
          price: wine.price,
        })),
        item_list_name: listName,
        page,
      },
    });
  }
}

export function logGoToStoreEvent(wine) {
  if (isNativeApp()) {
    FirebaseAnalytics.logEvent({
      name: "go_to_store",
      params: {
        item_id: wine.id,
        item_name: wine.name,
        affiliation: wine.shops ? wine.shops[0]?.chain.id : null,
        price: wine.price,
      },
    });
  }
}

export function logWineShareEvent(wine) {
  if (isNativeApp()) {
    FirebaseAnalytics.logEvent({
      name: "share",
      params: {
        item_id: wine.id,
        content_type: "wine",
      },
    });
  }
}

export function logFilterChangeEvent(name, value) {
  if (isNativeApp()) {
    FirebaseAnalytics.logEvent({
      name: "select_filter",
      params: {
        filter_name: name,
        filter_value: value,
      },
    });
  }
}

export function logSearchEvent(query) {
  if (isNativeApp()) {
    FirebaseAnalytics.logEvent({
      name: "search",
      params: {
        search_term: query,
      },
    });
  }
}

export function logError(error) {
  console.warn("Error:", error);
  if (isNativeApp()) {
    FirebaseAnalytics.logEvent({
      name: "error",
      params: {
        message: error.message,
      },
    });
  }
}
