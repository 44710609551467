<template>
  <div class="ion-page">
    <ion-content :fullscreen="true" class="ion-text-left">
      <ion-searchbar
        class="searchbar"
        :placeholder="$t('location.searchbarPlaceholder')"
        debounce="500"
        ref="searchbar"
        style="--box-shadow: 0"
        mode="md"
        :cancel-button-text="$t('search.searchbarCancel')"
        show-cancel-button="always"
        @ionChange="onSearch"
        @ionCancel="close"
      />
      <div v-show="isLoading" class="ion-text-center loading-spinner">
        <ion-spinner />
      </div>
      <ion-list v-show="!isLoading && !isError" lines="none">
        <template v-if="!query.length">
          <ion-item
            v-for="item in searchHistoryItems"
            :key="item.address"
            button
            @click="applyAutocomplete(item)"
          >
            <ion-label class="ion-text-wrap">
              {{ item.shortAddress }}
              <p>{{ item.city }}</p>
            </ion-label>
            <ion-icon :icon="historyIcon" slot="start" size="small"></ion-icon>
          </ion-item>
        </template>

        <ion-item
          v-if="!isBlocked"
          key="current"
          button
          @click="applyCurrentLocation"
        >
          <ion-label class="ion-text-wrap">
            <span style="vertical-align: top">{{
              $t("location.currentLocation")
            }}</span>
          </ion-label>
          <ion-icon :icon="navigateIcon" slot="start" size="small"></ion-icon>
        </ion-item>

        <ion-item
          v-for="item in items"
          :key="item.address"
          button
          @click="applyAutocomplete(item)"
        >
          <ion-label class="ion-text-wrap">
            {{ item.shortAddress }}
            <p>{{ item.city }}</p>
          </ion-label>
          <ion-icon :icon="locationIcon" slot="start" size="small"></ion-icon>
        </ion-item>
      </ion-list>
    </ion-content>
  </div>
</template>

<script>
import {
  IonContent,
  IonItem,
  IonList,
  IonLabel,
  IonSearchbar,
  IonIcon,
  modalController,
  IonSpinner,
} from "@ionic/vue";
import {
  close,
  navigateOutline,
  locationOutline,
  timeOutline,
} from "ionicons/icons";
//import { searchAddress, getLocationPermissions } from "@/lib/geolocationMapkit";
import { logError } from "@/lib/analytics";
import { getSearchHistory, addToSearchHistory } from "@/lib/searchHistory";

let searchAddress, getLocationPermissions

if (localStorage.getItem("useDaData") === "true") {
  searchAddress = require("@/lib/geolocation").searchAddress;
  getLocationPermissions = require("@/lib/geolocation").getLocationPermissions;
} else {
  searchAddress = require("@/lib/geolocationMapkit").searchAddress;
  getLocationPermissions = require("@/lib/geolocationMapkit").getLocationPermissions;
};

export default {
  components: {
    IonContent,
    IonItem,
    IonList,
    IonLabel,
    IonSpinner,
    IonSearchbar,
    IonIcon,
  },
  props: {
    address: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      query: "",
      closeIcon: close,
      navigateIcon: navigateOutline,
      locationIcon: locationOutline,
      historyIcon: timeOutline,
      isLoading: false,
      isError: false,
      isBlocked: false,
      items: [],
      searchHistoryItems: [],
    };
  },
  async mounted() {
    const permissions = await getLocationPermissions();
    if (permissions.location === "denied") {
      this.isBlocked = true;
    }

    setTimeout(() => {
      this.$refs.searchbar.$el.setFocus();
    }, 700);

    this.searchHistoryItems = await getSearchHistory("location");
  },
  methods: {
    onSearch(event) {
      this.query = event.detail.value;
      this.search(event.detail.value);
    },
    async search(query) {
      this.isError = false;
      if (!query) {
        this.items = [];
      } else {
        try {
          this.isLoading = true;
          this.items = await searchAddress(
            query,
            this.$i18n.locale,
            this.$store.state.location.location.latitude,
            this.$store.state.location.location.longitude,
          );
        } catch (e) {
          this.isError = true;
          logError(e);
          throw e;
        } finally {
          this.isLoading = false;
        }
      }
    },
    close() {
      modalController.dismiss(null);
    },
    applyCurrentLocation() {
      this.$store.dispatch("location/requestGeolocation");
      modalController.dismiss();
    },
    applyAutocomplete(autocompleteResult) {
      if (autocompleteResult.latitude && autocompleteResult.longitude) {
        // Save item to search history
        if (
          !this.searchHistoryItems.some(
            (item) => item.shortAddress === autocompleteResult.shortAddress,
          )
        ) {
          addToSearchHistory("location", autocompleteResult);
        }
        modalController.dismiss(autocompleteResult);
      }
    },
  },
};
</script>

<style scoped>
.ion-page {
  padding-top: var(--ion-safe-area-top);
}
.loading-spinner {
  margin: 30px 0;
}
:deep(.searchbar-input) {
    font-weight: 600;
    caret-color: var(--ion-color-primary);
  }
</style>
