<template>
  <ion-page>
    <ion-content>
      <h2 v-if="filtersLoading">Загрузка...</h2>
      <swiper v-else :pagination="true" ref="mySwiper">
        <swiper-slide>
          <div class="slide">
            <h2 class="slide-header">Выберите типа вина</h2>
            <div>
              <ion-button @click="setFilter({ wineType: 1 })"
                >Красное</ion-button
              >
            </div>
            <div>
              <ion-button @click="setFilter({ wineType: 2 })">Белое</ion-button>
            </div>
            <div>
              <ion-button @click="setFilter({ wineType: 4 })"
                >Игристое</ion-button
              >
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="slide">
            <h2 class="slide-header">Выберите сладость вина</h2>
            <div>
              <div>
                <ion-button @click="setFilter({ sugar: 1 })">Сухое</ion-button>
              </div>
              <div>
                <ion-button @click="setFilter({ sugar: 2 })"
                  >Полусухое</ion-button
                >
              </div>
              <div>
                <ion-button @click="setFilter({ sugar: 4 })"
                  >Полусладкое</ion-button
                >
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="slide">
            <h2 class="slide-header">Выберите стиль вина</h2>
            <div>
              <ion-button @click="setFilter({ style: 1 })"
                >Легкие фруктовые</ion-button
              >
            </div>
            <div>
              <ion-button @click="setFilter({ style: 2 })"
                >Морозные минеральные</ion-button
              >
            </div>
            <div>
              <ion-button @click="setFilter({ style: 3 })"
                >Средние спокойные</ion-button
              >
            </div>
            <div>
              <ion-button @click="setFilter({ style: 4 })"
                >Насыщеные сливочные</ion-button
              >
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="slide">
            <ion-spinner />
            <h2 class="slide-header">Подбираем вино...</h2>
          </div>
        </swiper-slide>
      </swiper>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { IonPage, IonContent, IonButton, IonSpinner } from "@ionic/vue";
import "swiper/css";
import "@ionic/vue/css/ionic-swiper.css";
import { Swiper, SwiperSlide } from "swiper/vue";

export default {
  components: {
    IonPage,
    IonContent,
    IonButton,
    IonSpinner,
    Swiper,
    SwiperSlide,
  },
  async created() {
    // this.$store.commit('location/setLocation', {
    //   latitude: parseFloat("59.939125"),
    //   longitude: parseFloat("30.3158225"),
    //   city: "Санкт-Петербург",
    //   countryCode: 'RU',
    //   isDefault: false,
    // });
    // await this.fetchFilters(this.$route.query);
  },
  computed: {
    ...mapState(["filters", "filtersLoading", "filtersValues"]),
  },
  methods: {
    ...mapActions(["fetchFilters"]),
    setFilter(update) {
      this.$store.commit("setFiltersValues", {
        ...this.filtersValues,
        ...update,
      });
      this.$refs.mySwiper.$el.swiper.slideNext();
    },
  },
};
</script>

<style scoped>
.swiper {
  width: 100%;
  height: 100%;
}
.slide {
  padding: 40px;
}
.slide-header {
  margin-bottom: 40px;
}
</style>
