import { isPlatform } from "@ionic/vue";

/**
 * Get object of query string params
 */
export function parseQueryString(search) {
  const queryString = search.split("?").pop();
  const searchParams = new URLSearchParams(queryString);
  const query = {};
  for (let param of searchParams) {
    query[param[0]] = param[1];
  }
  return query;
}

/**
 * Determine link depending on platform
 */
export function getAppStoreLink() {
  if (isPlatform("ios")) {
    return "https://apps.apple.com/ru/app/wineradar/id1545812738";
  }
  if (isPlatform("android")) {
    return "https://play.google.com/store/apps/details?id=com.wineradar.app";
  }
  return "";
}
