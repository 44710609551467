<template>
  <ion-page>
    <ion-header
      :translucent="true"
      class="ion-no-border"
      mode="ios"
      v-if="isNativeApp"
    >
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button router-link="/" router-direction="back">
            <ion-icon slot="icon-only" :icon="arrowBackIcon"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title class="long-title">{{ tag?.title }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-content ion-text-left" :fullscreen="true">
      <ion-grid v-if="!isNativeApp" fixed class="brand-header">
        <a href="https://wineradar.ru">
          <h1 class="brand-logo ion-padding">WINERADAR</h1>
        </a>
        <div class="ion-padding ion-hide-sm-down">
          <ion-button fill="outline" href="https://wineradar.ru"
            >✨ Скачать приложение</ion-button
          >
        </div>
      </ion-grid>

      <ion-header collapse="condense" class="ion-no-border" mode="ios">
        <ion-grid fixed style="padding: 0" class="about-grid">
          <ion-toolbar>
            <ion-title size="large">
              <div style="white-space: normal">
                {{ tag?.title }}
              </div>
            </ion-title>
          </ion-toolbar>
        </ion-grid>
      </ion-header>

      <ion-grid fixed class="about-grid about-grid-content">
        <ion-row
          v-if="tag"
          class="ion-padding"
          style="padding-top: 0; line-height: 22px; font-weight: 500"
        >
          <ion-col>
            {{ tag?.description }}
          </ion-col>
        </ion-row>

        <div
          v-if="isLocationBlocked || isLocationError"
          class="ion-padding"
          style="margin-left: 5px"
        >
          <city-selector @select="search" />
        </div>

        <div
          v-show="!isLoading && !isError && !wines.length"
          class="centered-container"
        >
          <div class="hint">{{ $t("tag.empty") }}</div>
        </div>

        <ion-row class="wine-list-error-container" v-show="isError">
          <ion-col>
            <error-message @retry="search" />
          </ion-col>
        </ion-row>

        <ion-row class="wine-list">
          <ion-col
            size-lg="4"
            size-md="6"
            size-sm="12"
            size-xs="12"
            v-for="wine in wines"
            :key="wine.id"
          >
            <wine-card :wine="wine" />
          </ion-col>
        </ion-row>
        <ion-row class="wine-list-loader" v-show="isLoading">
          <ion-col
            size-lg="4"
            size-md="6"
            size-sm="12"
            size-xs="12"
            v-for="index in 12"
            :key="index"
          >
            <wine-card-skeleton></wine-card-skeleton>
          </ion-col>
        </ion-row>

        <ion-infinite-scroll
          @ionInfinite="fetchMoreWines($event)"
          threshold="100px"
          id="infinite-scroll"
          :disabled="total - wines.length === 0"
        >
          <ion-infinite-scroll-content :loading-text="$t('common.loading')">
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </ion-grid>
    </ion-content>

    <ion-footer v-if="!isNativeApp" class="ion-no-border ion-hide-sm-up">
      <ion-toolbar>
        <div style="padding: 8px">
          <ion-button
            color="primary"
            mode="ios"
            fill="solid"
            expand="block"
            href="https://wineradar.ru"
          >
            {{ $t("wine.downloadAppButton") }}
          </ion-button>
        </div>
      </ion-toolbar>
    </ion-footer>
    <age-confirm />
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonContent,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonButtons,
  IonButton,
  IonRow,
  IonCol,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonIcon,
  IonFooter,
  isPlatform,
} from "@ionic/vue";
import { arrowBack, logoApple } from "ionicons/icons";
import axios from "axios";
import { mapState } from "vuex";

import AgeConfirm from "@/components/AgeConfirm.vue";
import WineCard from "@/components/WineCard.vue";
import WineCardSkeleton from "@/components/WineCardSkeleton.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";
import CitySelector from "@/components/CitySelector.vue";
import config from "@/app.config";
import { logWineListViewEvent, logError } from "@/lib/analytics";

export default {
  components: {
    AgeConfirm,
    ErrorMessage,
    IonPage,
    IonHeader,
    IonContent,
    IonTitle,
    IonToolbar,
    IonGrid,
    IonButtons,
    IonButton,
    IonRow,
    IonCol,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonIcon,
    IonFooter,
    WineCard,
    WineCardSkeleton,
    CitySelector,
  },
  data() {
    return {
      arrowBackIcon: arrowBack,
      logoApple,
      isLoading: false,
      isError: false,
      wines: [],
      page: 1,
      total: 0,
      tag: null,
    };
  },
  async created() {
    this.isLoading = true;
    this.fetchTag();
    if (this.location.isDefault) {
      await this.$store.dispatch("location/requestGeolocation");
    }
    this.search();
  },
  computed: {
    ...mapState("location", [
      "location",
      "isLocationBlocked",
      "isLocationError",
    ]),
    isNativeApp() {
      return isPlatform("capacitor");
    },
  },
  methods: {
    async fetchTag() {
      const { tag } = this.$route.params;
      if (tag) {
        const { data } = await axios.get(`${config.apiHost}/pairing/tag.ashx`, {
          params: { value: tag },
        });
        this.tag = data;
      }
    },
    async search() {
      this.wines = [];
      this.page = 1;
      await this.fetchWines();
    },
    async fetchWines() {
      this.isLoading = true;
      this.isError = false;
      try {
        const searchParams = {
          page: this.page,
          tag: this.$route.params.tag,
          location: {
            Latitude: this.$store.state.location.location.latitude,
            Longitude: this.$store.state.location.location.longitude,
          },
        };
        const { data } = await axios.post(
          `${config.apiHost}/pairing/getwines3.ashx`,
          JSON.stringify(searchParams),
        );
        if (data.page) {
          this.wines = this.wines.concat(data.page);
          this.total = data.total;
          logWineListViewEvent(this.wines, `Tag`, this.page);
        }
      } catch (e) {
        this.isError = true;
        this.isLoading = false;
        logError(e);
        throw e;
      }
      this.isLoading = false;
    },
    async fetchMoreWines(event) {
      this.page++;
      await this.fetchWines();
      event.target.complete();
    },
    openWinePage(id) {
      this.$router.push({
        name: "About",
        params: { id },
        query: {
          latitude: this.$store.state.location.latitude,
          longitude: this.$store.state.location.longitude,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
ion-title {
  position: static;
}
ion-title.long-title {
  padding-inline: 20px;
}
ion-footer {
  --background: transparent;
}
ion-toolbar {
  --background: transparent;
  --ion-color-base: transparent !important;
}
ion-button {
  font-weight: 700;
}
.download-button {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
.about-grid-content {
  height: 100%;
  padding-left: 0;
  padding-right: 0;
}
.centered-container {
  margin-top: calc(50% - 50px);
  text-align: center;
  opacity: 0.5;
}
.thumbnail {
  display: block;
  height: 70px;
  width: 50px;
  object-fit: contain;
}
.hint-icon ion-icon {
  font-size: 60px;
}
.hint {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 500;
}
</style>
