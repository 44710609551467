<template>
  <div style="background-color: #fff;" class="ion-padding">
    <div class="display-flex align-items-center">
      <ion-toggle :checked="useDaData" @ion-change="toggleUseDaData" /><small>Use DaData API</small>
    </div>
    <div class="display-flex align-items-center">
      <ion-toggle :checked="showGeolocationEvents" @ion-change="toggleShowGeolocationEvents" /><small>Show geolocation events</small>
    </div>
    <div class="display-flex align-items-center">
      <ion-toggle :checked="enableHighAccuracy" @ion-change="toggleEnableHighAccuracy" /><small>High accuracy geolocation</small>
    </div>
  </div>
</template>

<script>
import { IonToggle } from '@ionic/vue';

export default {
  name: "DebugSettings",
  components: {
    IonToggle,
  },
  data() {
    return {
      useDaData: false,
      showGeolocationEvents: false,
      enableHighAccuracy: false,
    };
  },
  created() {
    this.useDaData = localStorage.getItem("useDaData") === "true";
    this.showGeolocationEvents = localStorage.getItem("showGeolocationEvents") === "true";
    this.enableHighAccuracy = localStorage.getItem("enableHighAccuracy") === "true";
  },
  methods: {
    toggleUseDaData() {
      this.useDaData = !this.useDaData;
      localStorage.setItem("useDaData", this.useDaData ? "true" : "false");
    },
    toggleShowGeolocationEvents() {
      this.showGeolocationEvents = !this.showGeolocationEvents;
      localStorage.setItem("showGeolocationEvents", this.showGeolocationEvents ? "true" : "false");
    },
    toggleEnableHighAccuracy() {
      this.enableHighAccuracy = !this.enableHighAccuracy;
      localStorage.setItem("enableHighAccuracy", this.enableHighAccuracy ? "true" : "false");
    },
  },
};
</script>