<template>
  <filter-popover
    :value="filterLabel"
    :placeholder="$t('filters.priceLabel')"
    :apply-button="true"
    @apply="apply"
    @close="onClose"
    ref="popover"
    alias="price"
  >
    <div class="checkbox-list">
      <ion-item v-for="item in items" :key="item.id" lines="none">
        <ion-label class="price-label"
          >{{ item.label }}<br /><small>{{ item.sublabel }}</small></ion-label
        >
        <ion-checkbox
          mode="md"
          slot="start"
          class="price-checkbox"
          :checked="item.checked"
          @ionChange="select(item)"
        >
        </ion-checkbox>
      </ion-item>
    </div>
  </filter-popover>
</template>

<script>
import { IonCheckbox, IonItem, IonLabel } from "@ionic/vue";
import FilterPopover from "@/components/FilterPopoverNew.vue";
import { getCurrencyByCode } from "@/lib/currency";

const NO_LIMIT_VALUE = {
  from: 1,
  to: 1000000,
};

export default {
  components: {
    IonCheckbox,
    IonItem,
    IonLabel,
    FilterPopover,
  },
  props: {
    value: {
      type: Object,
      required: false,
    },
  },
  created() {
    const options = this.$store.state.filters.priceFilters || [];
    this.items = options.map((option) => ({
      from: option.priceMin,
      to: option.priceMax,
      label: this.getLabel(option.priceMin, option.priceMax, option.currency),
      currency: option.currency,
      checked: option.default,
    }));

    if (this.value) {
      this.appliedValue = { ...this.value };
      this.items = this.items.map((item) => {
        return {
          ...item,
          checked: item.from >= this.value.from && item.to <= this.value.to,
        };
      });
    }
  },
  data() {
    return {
      appliedValue: null,
      filterChanged: false,
      items: [],
    };
  },
  methods: {
    select(item) {
      item.checked = !item.checked;
      this.filterChanged = true;
    },
    onClose() {
      if (this.filterChanged) {
        this.apply();
      }
    },
    apply() {
      this.appliedValue = { ...this.selectedRange };
      this.filterChanged = false;
      this.$emit("select", this.selectedRange);
    },
    getLabel(from, to, currency) {
      const currencySymbol = getCurrencyByCode(currency);
      if (from === NO_LIMIT_VALUE.from && to === NO_LIMIT_VALUE.to) {
        return this.$t("filters.priceAny");
      }
      if (from === NO_LIMIT_VALUE.from) {
        return `${this.$t("filters.priceTo")} ${to} ${currencySymbol}`;
      }
      if (to === NO_LIMIT_VALUE.to) {
        return `${this.$t("filters.priceFrom")} ${from} ${currencySymbol}`;
      }
      return `${from} - ${to} ${currencySymbol}`;
    },
  },
  computed: {
    selectedRange() {
      if (this.selectedItems.length) {
        return {
          from: this.selectedItems[0].from,
          to: this.selectedItems[this.selectedItems.length - 1].to,
          currency: this.selectedItems[0].currency,
        };
      } else {
        return {
          from: 1,
          to: 1000000,
          currency: null,
        };
      }
    },
    selectedItems() {
      return this.items.filter((item) => item.checked === true);
    },
    filterLabel() {
      if (this.appliedValue) {
        return this.getLabel(
          this.appliedValue.from,
          this.appliedValue.to,
          this.currency,
        );
      }
      return "";
    },
    currency() {
      return this.$store.state.filters.priceFilters[0].currency;
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox-list {
  padding-bottom: 5px;
}
.price-label {
  margin-top: 10px;
}
.price-checkbox {
  margin: 10px 15px 10px 0px;
}
</style>
