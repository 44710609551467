import axios from "axios";
import config from "@/app.config";

export async function toggleFavoriteWineLegacyId(id) {
  axios.post(`${config.apiHost}/pairing/favorites.ashx`, { id });
}

export async function toggleFavoriteWine(id) {
  axios.post(`${config.apiHost}/pairing/favorites2.ashx`, { id });
}
