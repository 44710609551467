export default (url, maxWidth = 1024, maxHeight = 1024) => {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = url;

    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // Calculate crop dimensions (70% width, 50% height)
      const cropWidth = image.width * 0.7;
      const cropHeight = image.height * 0.5;

      console.log(cropWidth, cropHeight);

      // Calculate crop position (centered)
      const cropX = (image.width - cropWidth) / 2;
      const cropY = (image.height - cropHeight) / 2;

      // Calculate scale to fit within maxWidth and maxHeight
      const scale = Math.min(maxWidth / cropWidth, maxHeight / cropHeight);
      const newWidth = cropWidth * scale;
      const newHeight = cropHeight * scale;

      // Set canvas dimensions to the new size
      canvas.width = newWidth;
      canvas.height = newHeight;

      // Draw cropped and resized image
      ctx.imageSmoothingEnabled = true;
      ctx.drawImage(
        image,
        cropX, cropY, cropWidth, cropHeight,
        0, 0, newWidth, newHeight
      );

      resolve(ctx.canvas.toDataURL("image/jpeg", 0.95));
    };
  });
};
