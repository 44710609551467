<template>
  <div class="ion-text-center">
    <img src="@/assets/error.svg" alt="Error" width="100" />
    <h3 class="header">{{ $t("error.generalTitle") }}</h3>
    <p class="message">
      <small>{{ $t("error.generalDescription") }}</small>
    </p>
    <ion-button
      color="primary"
      fill="outline"
      size="small"
      :strong="true"
      mode="ios"
      @click="$emit('retry')"
    >
      {{ $t("error.tryAgainButton") }}
    </ion-button>
  </div>
</template>

<script>
import { IonButton } from "@ionic/vue";

export default {
  components: {
    IonButton,
  },
};
</script>

<style scoped>
.header {
  margin-bottom: 0;
}
.message {
  margin-top: 0;
}
</style>
