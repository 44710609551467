<template>
  <div>
    <!-- <div v-if="shops.length || isLoading" class="ion-padding section-header">Магазины</div> -->
    <div v-if="isLoading" class="shops skeleton">
      <div v-for="index in 5" :key="index" class="shop">
        <ion-card class="shop-card" mode="ios">
          <div class="shop-content">
            <ion-skeleton-text
              animated
              style="width: 90%; height: 12px"
              class="shop-text-placeholder"
            />
            <ion-skeleton-text
              animated
              style="width: 60%; height: 12px"
              class="shop-text-placeholder"
            />
            <ion-skeleton-text
              animated
              style="width: 40%; height: 28px"
              class="shop-text-placeholder"
            />
          </div>
        </ion-card>
      </div>
    </div>
    <div v-if="!isLoading && shops.length" class="shops">
      <div
        v-for="shop in shops"
        :key="shop.id"
        class="shop"
        :id="`shop${shop.id}`"
      >
        <ion-card
          class="shop-card"
          :class="{ selected: shop.id === value }"
          :button="true"
          mode="ios"
          @click="toggle(shop.id)"
        >
          <div class="shop-content">
            <div class="shop-header">
              <!-- <div class="shop-distance">
                <template v-if="shop.distance > 1000">
                  {{
                    $tc(
                      "filters.shopDistanceKilometers",
                      Math.round(shop.distance / 1000),
                    )
                  }}
                </template>
                <template v-else-if="shop.distance < 30">
                  <span style="color: green">Вы здесь</span>
                </template>
                <template v-else>
                  {{ $tc("filters.shopDistanceMeters", shop.distance) }}
                </template>
              </div> -->
              <div class="shop-distance">
                {{ shop.chain.name }}
              </div>
              <ion-icon :icon="closeIcon" color="primary" class="closeIcon"></ion-icon>
            </div>
            <div class="shop-address">{{ getShortAddress(shop.address) }}</div>
            <img
              v-if="shop.chain.url"
              :src="`https://app.wineradar.ru${shop.chain.url}`"
              @dragstart.prevent
              :alt="shop.chain.name"
              :title="shop.chain.name"
            />
          </div>
        </ion-card>
      </div>
    </div>
  </div>
</template>

<script>
import { IonCard, IonSkeletonText, IonIcon } from "@ionic/vue";
import { close } from "ionicons/icons";
import { mapState } from "vuex";
import { logFilterChangeEvent } from "@/lib/analytics";

export default {
  components: {
    IonCard,
    IonSkeletonText,
    IonIcon,
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      closeIcon: close,
    };
  },
  computed: {
    ...mapState("shops", ["shops", "isLoading"]),
    ...mapState("location", ["location"]),
  },
  methods: {
    toggle(shopId) {
      if (this.value === shopId) {
        this.$emit("select", 0);
      } else {
        this.$emit("select", shopId);
        logFilterChangeEvent("shop", shopId);
      }
      document.getElementById("shop" + shopId).scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    },
    getShortAddress(address) {
      // Disable short address because it works incorrectly

      // if (this.location.city) {
      //   return address.replace(this.location.city, "").replace(/^(г.? )?,/, "");
      // }

      return address;
    },
  },
};
</script>

<style scoped lang="scss">
.shops {
  --shop-height: 110px;
  --shop-width: 190px;
  --shop-gap: 16px;
  --border-radius: 15px;
  --shop-font-size: 1em;

  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  flex-wrap: nowrap;
  // padding-left: 10px;
  // margin-left: -10px;
  // scroll-snap-type: x mandatory; /* scrollIntoView does not work in Safari iOS with scroll-snap */

  &::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    height: 0px;
    display: none;
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  &.skeleton {
    display: flex;
    overflow-x: hidden;
    overflow-y: visible;
    flex-wrap: nowrap;

    &::-webkit-scrollbar {
      width: 0px; /* Remove scrollbar space */
      height: 0px;
      background: transparent; /* Optional: just make scrollbar invisible */
    }
  }
}
.shop-text-placeholder {
  margin-bottom: 8px;
}
.shop {
  //scroll-snap-align: center;
  padding: 16px;
  flex-shrink: 0;
  width: calc(var(--shop-width) + var(--shop-gap));

  .shop-card {
    margin: 0;
    border-radius: var(--border-radius);
    width: var(--shop-width);
    height: var(--shop-height);
    position: relative;
    font-weight: 500;
    border: 1px transparent solid;

    --background: #fff;
    //box-shadow: none;
    //border: 1px var(--ion-color-dark) solid;


    &.selected {
      border: 1px var(--ion-color-primary) solid;
      // transform: translateY(-6px);

      .closeIcon {
        display: block;
      }
    }

    .shop-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .closeIcon {
      display: none;
    }

    .shop-content {
      padding: 12px;
      height: var(--shop-height);
    }

    .shop-address {
      color: var(--ion-color-dark);
      margin-top: 5px;
      margin-bottom: 10px;
      font-size: 12px;
      display: block;
      height: 30px;
      overflow: hidden;
    }

    .shop-distance {
      color: var(--ion-color-primary);
      font-weight: 600;
      font-size: 14px;
    }

    img {
      max-height: 22px;
      max-width: 80%;
    }
  }
}
</style>
