<template><div></div></template>

<script>
import { InAppReview } from '@capacitor-community/in-app-review';
import { toastController, isPlatform } from "@ionic/vue";

export default {
  mounted() {
    setTimeout(() => {
      if (isPlatform("capacitor") && this.$store.state.config.rateThisApp) {
        this.openRateToast();
      }
    }, 3000);
  },
  methods: {
    async openRateToast() {
      const toast = await toastController.create({
        color: "primary",
        cssClass: "rate-toast ion-text-left",
        header: "",
        message: this.$t("rateThisApp.question"),
        position: "bottom",
        buttons: [
          {
            text: this.$t("rateThisApp.yes"),
            role: "rate",
            handler: () => {
              RateApp.requestReview();
            },
          },
          {
            text: this.$t("rateThisApp.no"),
            role: "close",
            handler: () => {
              InAppReview.requestReview();
            },
          },
        ],
      });
      return toast.present();
    },
  },
};
</script>
