<template>
  <ion-card
    class="wine-card"
    mode="ios"
    style="margin-top: 0"
    :button="true"
    @click="openWinePage"
  >
    <ion-card-content v-if="wine" class="wine-container">
      <figure>
        <img
          v-if="!wineImageError"
          :src="wineImageUrl"
          @error="wineImageError = true"
        />
        <wine-image-placeholder v-if="wineImageError" />
      </figure>
      <div class="wine-details">
        <div class="wine-title">
          <span>{{ wine.name }}</span>
        </div>
        <div v-if="wine.rating?.score" class="wine-rating">
          <span class="rating">{{ wine.rating.score.toFixed(1) }}</span>
          <rating
            :rating="wine.rating.score"
            size="small"
            style="padding-right: 8px"
          />
          <br />
          <div v-if="wine.rating.ratingsCount" class="rating-count">
            <span>{{ wine.rating.ratingsCount }}</span>
            <small>{{ $tc("wine.reviewsCount", wine.rating.ratingsCount) }}</small>
          </div>
          <div class="rating-badge">
            <small v-if="wine.rating.ratingsCount > 10000" class="good">{{ $t('wine.manyReviews') }}</small>
            <small v-if="wine.rating.ratingsCount < 300" class="bad">{{ $t('wine.fewReviews') }}</small>
          </div>
        </div>
        <div class="wine-group">{{ wine.type?.name }}</div>
        <div class="wine-country">{{ wine.country }}</div>
        <div class="wine-price">
          <div class="shop-box" v-if="shop">
            <div style="display: flex; justify-content: space-between">
              <div v-if="price" class="price">
                <div class="actual-price">
                  {{ Math.floor(price) }}
                  {{ getCurrencyByCode(currency) }}
                </div>
                <div v-if="basePrice && basePrice !== price" class="base-price">
                  {{ Math.floor(basePrice) }}
                </div>
              </div>
              <div class="logo">
                <img :src="shopLogo" />
              </div>
            </div>
            <div v-if="wine.hasOnlineDiscount" class="price-description">
              <span class="badge">{{ $t("wine.onlinePrice") }}</span>
            </div>
            <small class="address">{{ shop.address }}</small>
            <div v-if="wine.shops && wine.shops.length > 1" class="more-shops">
              {{ $tc("wine.moreShops", wine.shops.length - 1) }}
            </div>
          </div>
          <div v-else>
            <small>{{ $t("wine.outOfStock") }}</small>
          </div>
        </div>
      </div>
    </ion-card-content>
  </ion-card>
</template>

<script>
import { IonCard, IonCardContent } from "@ionic/vue";
import { logWineSelectEvent } from "@/lib/analytics";
import Rating from "@/components/Rating";
import WineImagePlaceholder from "@/components/WineImagePlaceholder";
import { getCurrencyByCode } from "@/lib/currency";

export default {
  components: {
    IonCard,
    IonCardContent,
    Rating,
    WineImagePlaceholder,
  },
  emits: ["click"],
  props: {
    wine: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      wineImageError: false,
    };
  },
  computed: {
    shop() {
      return this.wine.shops && this.wine.shops[0];
    },
    shopLogo() {
      const path = this.shop?.chain.logo;
      return path ? `https://app.wineradar.ru${path}` : null;
    },
    price() {
      return this.wine.price || this.shop?.price;
    },
    currency() {
      return this.wine.currency || this.shop?.currency;
    },
    basePrice() {
      return this.wine.basePrice || this.shop?.basePrice;
    },
    wineImageUrl() {
      return this.wine.imageUrl?.startsWith("//")
        ? `https:${this.wine.imageUrl}`
        : this.wine.imageUrl;
    },
  },
  methods: {
    openWinePage() {
      this.$emit("click");
      this.$store.commit("setCurrentWine", this.wine);
      this.$router.push({
        name: "About",
        params: {
          id: this.wine.id,
        },
        query: {
          latitude: this.$store.state.location.latitude,
          longitude: this.$store.state.location.longitude,
        },
      });
      logWineSelectEvent(this.wine);
    },
    getCurrencyByCode,
  },
};
</script>

<style lang="scss">
.wine-card {
  --background: #fff;
  height: calc(100% - 16px);
}
ion-card.wine-card::part(native) {
  height: 100%;
}
.wine-container {
  display: flex;
  height: 100%;

  figure {
    margin: 0 10px 0 10px;
    width: 20%;
    flex: 0 0 20%;

    img {
      width: 100%;
      max-width: 300px;
    }
  }

  .badge {
    color: green;
    color: var(--ion-color-primary);
    font-size: 12px;
  }

  .wine-details {
    display: flex;
    flex-direction: column;
    padding: 0 0 0 20px;
    border-radius: 10px;
    flex-grow: 1;
    text-align: left;

    .wine-title {
      color: var(--ion-color-dark-tint);
      font-size: 18px;
      font-weight: 800;
      line-height: 22px;
      display: flex;
      align-items: center;
      padding-bottom: 10px;

      span {
        display: block;
      }
    }
    .wine-rating {
      padding-bottom: 10px;
      line-height: 18px;

      .rating {
        font-size: 20px;
        padding-left: 0px;
        padding-right: 5px;
        vertical-align: text-center;
        color: var(--ion-color-primary);
      }
      .rating-count {
        margin-top: 5px;
        font-weight: 500;

        span {
          padding-right: 4px;
          font-size: 0.8em;
        }
      }
      .rating-badge {
        font-weight: 700;

        .good {
          color: green;
        }
        .bad {
          color: red;
        }
      }
    }
    .wine-price {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-top: 20px;

      .price {
        color: var(--ion-color-primary-tint);
        font-size: 24px;
        font-weight: 300;
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        margin-right: 5px;
      }
      .actual-price {
        padding-right: 5px;
        white-space: nowrap;
      }
      .base-price {
        color: inherit;
        font-size: 12px;
        font-weight: 400;
        text-decoration: line-through;
      }
      .vendor-logo {
        height: 20px;
        width: auto;
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
      }
      .price-description {
        line-height: 12px;
        margin-bottom: 10px;
      }
    }
  }

  .shop-box {
    border: 1px #eee solid;
    padding: 6px 10px;
    border-radius: 8px;
    width: 100%;

    .address {
      color: var(--ion-color-dark);
      margin-top: 5px;
    }

    .more-shops {
      color: var(--ion-color-dark);
      font-weight: 500;
      font-size: 1em;
      margin-top: 10px;
    }

    .logo {
      margin-top: 5px;

      img {
        object-fit: contain;
        //min-width: 26px;
        height: 20px;
        max-height: 26px;
      }
    }
  }
}
</style>
