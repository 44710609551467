<template>
  <ion-card mode="ios" class="wine-card" style="margin-top: 0">
    <ion-card-content class="wine-container">
      <figure>
        <wine-image-placeholder />
      </figure>
      <div class="wine-details">
        <div class="wine-title">
          <ion-skeleton-text animated style="width: 100%; height: 35px" />
        </div>

        <div class="wine-group">
          <ion-skeleton-text animated style="width: 40%; height: 16px" />
        </div>
        <div class="wine-country">
          <ion-skeleton-text animated style="width: 50%; height: 16px" />
        </div>
        <div class="wine-rating">
          <ion-skeleton-text animated style="width: 40%; height: 25px" />
        </div>
        <div class="wine-price">
          <div class="price">
            <ion-skeleton-text animated style="width: 100%; height: 55px" />
          </div>
        </div>
      </div>
    </ion-card-content>
  </ion-card>
</template>

<script>
import { IonCard, IonCardContent, IonSkeletonText } from "@ionic/vue";
import WineImagePlaceholder from "@/components/WineImagePlaceholder";

export default {
  components: {
    IonCard,
    IonCardContent,
    IonSkeletonText,
    WineImagePlaceholder,
  },
};
</script>

<style lang="scss" scoped>
.wine-card {
  min-height: 280px;
}
.wine-container {
  display: flex;
  height: 100%;

  figure {
    margin: 0 20px 0 20px;
    width: 18%;
    flex: 0 0 18%;
    max-width: 300px;
  }

  .wine-details {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding: 0 20px 0 20px;
    flex-grow: 1;
    // background: rgb(244, 245, 248);
    border-radius: 10px;

    .wine-title {
      // min-height: 70px;
      color: var(--ion-color-dark-tint);
      font-size: 18px;
      font-weight: 800;
      line-height: 22px;
      display: flex;
      align-items: center;
      padding-bottom: 10px;

      span {
        display: block;
      }
    }

    .wine-rating {
      color: var(--ion-color-primary);
      padding-top: 5px;
      padding-bottom: 10px;

      .rating {
        font-size: 18px;
        font-weight: 300;
        padding-left: 5px;
      }
    }
    .wine-price {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .price {
        display: block;
      }
      .price {
        padding-bottom: 5px;
      }
      .base-price {
        color: inherit;
        font-size: 18px;
        font-weight: 400;
        text-decoration: line-through;
      }
      .vendor-logo {
        height: 20px;
        width: auto;
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
      }
    }
  }
}
</style>
