<template>
  <span>
    <ion-icon
      v-for="score in [1, 2, 3, 4, 5]"
      :key="score"
      name="star"
      :class="{ small: size === 'small' }"
      color="primary"
      :src="getIcon(score)"
    >
    </ion-icon>
  </span>
</template>

<script>
import { IonIcon } from "@ionic/vue";
import { star, starHalf, starOutline } from "ionicons/icons";

export default {
  components: {
    IonIcon,
  },
  props: {
    rating: {
      type: Number,
      default: 0,
    },
    size: {
      type: String,
      default: "default",
    },
  },
  data() {
    return {
      star,
      starHalf,
      starOutline,
    };
  },
  methods: {
    getIcon(score) {
      if (score <= this.rating) {
        return this.star;
      } else if (score - this.rating > 0 && score - this.rating < 1) {
        return this.starHalf;
      } else {
        return this.starOutline;
      }
    },
  },
};
</script>

<style scoped>
ion-icon.small {
  font-size: 14px;
}
</style>
