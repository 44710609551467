<template>
  <div class="display-flex align-items-center">
    <!-- <label>Сортировать по</label> -->
    <ion-button
      fill="clear"
      mode="ios"
      color="dark"
      class="sort-button"
      :strong="true"
      @click="onClick"
    >
      <ion-icon
        slot="start"
        :icon="orderIcon"
        color="dark"
        size="small">
      </ion-icon>
      <ion-icon
        v-if="options.length > 1"
        slot="end"
        :icon="changeIcon"
        color="primary"
        size="small">
      </ion-icon>
      <label>{{ label }}</label>
    </ion-button>
    <ion-modal
      :is-open="isOpen"
      class="modal"
      mode="ios"
      :initial-breakpoint="1"
      :breakpoints="[0, 1]"
      @willDismiss="isOpen = false"
    >
      <div class="block">
        <ion-list lines="none">
          <ion-list-header mode="ios" style="margin-bottom: 10px">
            <ion-label>Сортировать</ion-label>
          </ion-list-header>
          <ion-radio-group :value="value" @ionChange="onChange" mode="md">
            <ion-item v-for="option in options" :key="option.id">
              <ion-radio slot="start" :value="option.id"></ion-radio>
              <ion-label>{{ option.name }}</ion-label>
            </ion-item>
          </ion-radio-group>
        </ion-list>
      </div>
    </ion-modal>
  </div>
</template>

<script>
import { IonButton, IonIcon, IonModal, IonList, IonListHeader, IonItem, IonLabel, IonRadioGroup, IonRadio } from "@ionic/vue";
import { locationOutline, chevronForwardOutline, swapVertical, filter } from "ionicons/icons";

export default {
  components: {
    IonIcon,
    IonButton,
    IonModal,
    IonList,
    IonItem,
    IonLabel,
    IonRadioGroup,
    IonRadio,
    IonListHeader,
  },
  emits: ['change'],
  data() {
    return {
      locationIcon: locationOutline,
      changeIcon: chevronForwardOutline,
      orderIcon: filter,
      isOpen: false,
      value: 'score',
      options: [
        { id: 'score', name: 'По рейтингу Vivino', label: 'По рейтингу Vivino' },
        //{ id: 'price', name: 'По возрастанию цены', label: 'По цене' },
        //{ id: 'discount', name: 'По скидке', label: 'По скидке'},
      ],
    };
  },
  computed: {
    label() {
      return this.options.find(option => option.id === this.value).label;
    },
  },
  methods: {
    onClick() {
      if (this.options > 1) {
        this.isOpen = true;
      }
    },
    onChange(event) {
      this.value = event.detail.value;
      this.$emit('change', this.value);
      this.isOpen = false;
    },
  },
}
</script>

<style scoped>

.block {
  width: 100%;
  height: 200px;
}
label {
  margin-left: 5px;
}

.modal {
  --height: auto;
  --border-radius: 15px;
}
</style>
