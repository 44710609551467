<template>
  <div ref="mapContainer"></div>
</template>

<script>
export default {
  props: {
    // deprecated, use "items"
    latitude: {
      type: Number,
      required: false,
    },
    // deprecated, use "items"
    longitude: {
      type: Number,
      required: false,
    },
    // deprecated, use "items"
    title: {
      type: String,
      required: false,
    },
    /**
     * @type {Array<{ title: string, latitude: number, longitude: number }>}
     */
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    userLocation: ({ $store }) => $store.state.location.location,
  },
  mounted() {
    const center = new window.mapkit.Coordinate(this.latitude, this.longitude);
    const span = new window.mapkit.CoordinateSpan(0.1, 0.1);
    const region = new window.mapkit.CoordinateRegion(center, span);
    const shop = new window.mapkit.MarkerAnnotation(center, {
      title: this.title,
      color: "#BB3333",
    });

    const userCoorinate = new window.mapkit.Coordinate(
      this.userLocation.latitude,
      this.userLocation.longitude,
    );
    const user = new window.mapkit.MarkerAnnotation(userCoorinate, {
      title: this.userLocation.shortAddress,
      color: "#3483E7",
    });

    const map = new window.mapkit.Map(this.$refs.mapContainer, {
      region,
      showsCompass: mapkit.FeatureVisibility.Adaptive,
      isScrollEnabled: false,
    });

    if (this.items.length) {
      const annotations = this.items.map((item) => {
        const coordinate = new window.mapkit.Coordinate(item.latitude, item.longitude);
        return new window.mapkit.MarkerAnnotation(coordinate, {
          title: item.title,
          color: "#BB3333",
        });
      });
      map.showItems([...annotations, user]);
    } else {
      map.showItems([shop, user]);
    }
  },
};
</script>
