export function getViewedStories() {
  const stories = JSON.parse(localStorage.getItem("viewedStories"));
  return stories ?? [];
}

export function markStoryAsViewed(id) {
  const stories = getViewedStories();
  if (!stories.includes(id)) {
    stories.push(id);
    localStorage.setItem("viewedStories", JSON.stringify(stories));
  }
}
