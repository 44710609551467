<template>
  <ion-modal
    id="age-confirm-modal"
    ref="modal"
    :is-open="isOpen"
    :backdrop-dismiss="false"
  >
    <div class="wrapper ion-text-center">
      <img src="@/assets/stop.svg" alt="Error" width="200" />
      <h2>{{ $t("ageConfirm.text") }}</h2>
      <div class="ion-text-center">
        <ion-button @click="confirm()" mode="ios" shape="round">{{ $t("ageConfirm.button")}}</ion-button>
      </div>
    </div>
  </ion-modal>
</template>

<script>
import { IonButton, IonModal } from "@ionic/vue";

export default {
  components: {
    IonButton,
    IonModal,
  },
  data: () => ({
    isOpen: false,
  }),
  async mounted() {
    await this.$store.dispatch("fetchAgeConfirmed");
    if (this.$i18n.locale === "ru" && !this.$store.state.isAgeConfirmed) {
      this.isOpen = true;
    }
  },
  methods: {
    confirm() {
      this.$store.dispatch("confirmAge");
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss">
ion-modal#age-confirm-modal {
  --width: fit-content;
  --min-width: 250px;
  --height: fit-content;
  --border-radius: 20px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.7);

  h1 {
    margin: 20px 20px 10px 20px;
    font-size: 3em;
  }
  h2 {
    margin: 20px;
    font-size: 1em;
    font-weight: 500;
  }

  .wrapper {

    margin: 20px 0;
    max-width: 600px;
    width: 80vw;
  }

}
</style>
