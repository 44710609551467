<template>
  <ion-card
    v-if="serviceMessage"
    v-show="!closed"
    class="ion-padding"
    style="margin-top: 0"
    mode="ios"
  >
    <div class="display-flex">
      <div class="alert-icon">
        <ion-icon :icon="alertCircleOutline" />
      </div>
      <div class="message-text">{{ serviceMessage }}</div>
      <ion-button
        @click="closed = true"
        size="small"
        fill="clear"
        color="dark"
        class="close-button"
      >
        <ion-icon slot="icon-only" :icon="closeOutline"></ion-icon>
      </ion-button>
    </div>
  </ion-card>
</template>

<script>
import { alertCircleOutline, closeOutline } from "ionicons/icons";
import { IonButton, IonCard, IonIcon } from "@ionic/vue";

export default {
  components: {
    IonButton,
    IonCard,
    IonIcon,
  },
  data() {
    return {
      alertCircleOutline,
      closeOutline,
      closed: false,
    };
  },
  computed: {
    serviceMessage() {
      return this.$store.state.config?.serviceMessage;
    },
  },
};
</script>

<style lang="scss" scoped>
.alert-icon {
  padding-right: 10px;
  font-size: 30px;
  color: var(--ion-color-primary);
}
.message-text {
  color: var(--ion-color-dark);
  // font-weight: 600;
}
.close-button {
  --ionicon-stroke-width: 40px;
  --padding-end: 0;
}
</style>
