<template>
  <figure class="wine-image">
    <img :src="wineImageUrl" @click="zoom" />
  </figure>
  <div v-if="isZoomed" class="zoomed">
    <img :src="wineImageUrl" @click="zoom" />
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isZoomed: false,
    };
  },
  computed: {
    wineImageUrl() {
      return this.url.startsWith("//") ? `https:${this.url}` : this.url;
    },
  },
  methods: {
    zoom() {
      this.isZoomed = !this.isZoomed;
    },
  },
};
</script>

<style scoped lang="scss">
.wine-image {
  min-width: 135px;
  max-width: 175px;
  position: relative;
  z-index: 1000;
  margin: 0;
  padding-bottom: 20px;
  text-align: center;

  img {
    height: 550px;
    width: auto;
    object-fit: cover;
    transition: all 0.2s ease-in-out;
  }

  @media only screen and (max-width: 600px) {
    img {
      height: 370px;
      width: auto;
    }
  }
}
.zoomed {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  text-align: center;

  img {
    height: 100%;
    width: auto;
    object-fit: contain;
  }
}
</style>
