import axios from "axios";
import config from "@/app.config";

export default {
  namespaced: true,
  state: () => ({
    shops: [],
    isLoading: true,
  }),
  mutations: {
    setShops(state, shops) {
      state.shops = shops;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
  },
  actions: {
    /**
     * Get nearby shops
     */
    async fetchShops({ rootState, commit }, shopChain) {
      commit("setIsLoading", true);
      const result = await axios.get(`${config.apiHost}/pairing/shops.ashx`, {
        params: {
          latitude: rootState.location.location.latitude,
          longitude: rootState.location.location.longitude,
          shopChain,
        },
      });
      commit("setShops", result.data);
      commit("setIsLoading", false);
    },
  },
};
